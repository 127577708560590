import 'antd/dist/antd.min.css'
import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import './App.css'
import LanguageSelector from './components/GlobalComponents/LanguageSelector'
import ProtectedRoute from './components/GlobalComponents/ProtectedRoute'
import {handleSaveServiceCode} from './features/user/userInfoSlice'

import './index.css'
import './responsive.css'
import ConfirmCodeScreen from './screens/AuthScreen/ConfirmCodeScreen'
import ActiveAccountScreen from './screens/AuthScreen/ActiveAccountScreen'

import ForgotPasswordScreen from './screens/AuthScreen/ForgotPasswordScreen'
import LoginRegisterScreen from './screens/AuthScreen/LoginRegisterScreen'
import SetNewPasswordScreen from './screens/AuthScreen/SetNewPasswordScreen'
import RecaptchaScreen from './screens/RecaptchaScreen'
import {StyledGlobalStyles} from './stylesUtils/styles'

function App() {
  const dispatch = useDispatch()

  useEffect(() => {
    let pathName = window.location.href
    // eslint-disable-next-line no-unused-vars
    const [url, serviceCode] = pathName.split('code=')

    localStorage.setItem('serviceCode', serviceCode)
    dispatch(handleSaveServiceCode(serviceCode))
    localStorage.setItem('isVerifiedCode', false)
  }, [dispatch])

  return (
    <StyledGlobalStyles>
      <Router>
        <Switch>
          <Route path='/' exact component={LoginRegisterScreen} />

          <Route
            path='/forgot-password'
            exact
            component={ForgotPasswordScreen}
          />

          <Route
            path='/recaptcha'
            exact
            component={RecaptchaScreen}
          />

          <ProtectedRoute
            path='/reset-password'
            exact
            component={SetNewPasswordScreen}
          />

          <ProtectedRoute
            path='/confirm-code'
            exact
            component={ConfirmCodeScreen}
          />
          <Route
            path='/activate-account'
            exact
            component={ActiveAccountScreen}
          />
        </Switch>
      </Router>
      <LanguageSelector />
    </StyledGlobalStyles>
  )
}

export default App
