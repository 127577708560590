import styled from 'styled-components'
import {WHITE_COLOR} from '../../../data/constants'

export const StyledSelect = styled.div`
  position: absolute;
  top: 3vh;
  right: 3vw;

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: none;
    border-radius: 0.8rem;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent;
    box-shadow: 0 0 0 2px transparent;
  }

  .ant-select-arrow {
    color: ${WHITE_COLOR};
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
  .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
    display: flex;
    align-items: center;
  }
`
