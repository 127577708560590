import styled from "styled-components";
import { PRIMARY_COLOR, WHITE_COLOR } from "../../../../data/constants";

export const RegisterFormSectionContainer = styled.div``;

export const RegisterFormSectionMain = styled.div`
  display: flex;
`;

export const SectionMainLeft = styled.div`
  border-radius: 0.9rem 0 0 0.9rem;
  width: 100%;

  @media only screen and (max-width: 61.96875em) {
    border-radius: 0.9rem;
  }
`;

export const FormContainer = styled.div`
  @media only screen and (max-width: 60em) {
  }

  .ant-input-group-addon {
    border-radius: 0.8rem 0 0 0.8rem;
    background-color: transparent;
    border: 1px solid ${WHITE_COLOR};
  }

  form .ant-row.ant-form-item {
    display: flex;
    flex-direction: column;
  }

  .ant-form-item-label {
    text-align: start;
  }

  .ant-form-item-label > label::after {
    content: " ";
  }

  .ant-form-item {
    font-size: 1.4rem;
    margin-bottom: 2.4rem;
  }

  .ant-form-item-has-error.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item-has-success.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-form-item-label
    > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: inline-block;
    margin-right: 0;
    color: transparent;
    font-size: 0;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*";
  }

  .ant-input,
  .ant-form-item-label > label {
    font-size: 1.7rem;
    background: transparent;
    color: ${WHITE_COLOR};
  }

  .ant-form label {
    font-size: 1.7rem !important;
    line-height: 2.1 !important;
  }

  .ant-radio-group {
    width: 73%;
    justify-content: space-between;
    display: flex;
  }

  .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: 0 0 0 3px transparent;
  }

  .ant-radio-wrapper {
    margin: 0;
  }

  .ant-radio-group.ant-radio-group-outline > label > span {
    color: #fff;
  }

  .ant-radio-inner {
    background-color: transparent;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #ff4d4f;
    font-size: 1.7rem;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 3rem;
    font-size: 1.7rem;
    color: ${WHITE_COLOR};
  }

  .ant-btn > span {
    display: inline-block;
    font-size: 2rem;
  }

  .ant-form-item-control-input-content {
    flex: auto;
    max-width: 100%;
    font-size: 1.7rem;
    display: flex;
    justify-content: flex-start;
  }

  .ant-form-item .ant-picker-calendar-year-select,
  .ant-form-item .ant-picker-calendar-month-select,
  .ant-form-item .ant-input-group .ant-select,
  .ant-form-item .ant-input-group .ant-cascader-picker {
    width: 9rem;
  }

  .ant-select-item {
    font-size: 1.7rem;
  }

  .ant-select-single.ant-select-open .ant-select-selection-item {
    font-size: 1.7rem;
  }

  .ant-select-item-option-content {
    text-overflow: ellipsis;
    font-size: 1.7rem;
  }

  .ant-btn.ant-btn-primary {
    height: 4.6rem;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-input {
    line-height: 2.1;
  }

  .ant-input-affix-wrapper {
    background-color: transparent;
  }

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    background: rgba(20, 146, 230, 0.5);
    color: #ffffff;
  }

  .ant-form-item-control-input-content {
    display: flex;
  }

  .ant-input-password-icon {
    color: ${WHITE_COLOR};
  }

  .ant-form-item-has-error.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    color: #f5f5f5;
  }

  .ant-form-item-has-success.ant-form-item-has-feedback
    .ant-form-item-children-icon {
    color: #1492e6;
  }

  .ant-tabs-top > .ant-tabs-nav,
  .ant-tabs-bottom > .ant-tabs-nav,
  .ant-tabs-top > div > .ant-tabs-nav,
  .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0 0 4.4rem 0;
  }

  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
    background-color: transparent;
    border-color: #fff;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    color: #fff;
    width: 38rem;
  }

  .custom-helper
    .ant-col.ant-form-item-control
    > div:nth-child(2)
    .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: -46%;
  }

  .ant-btn-primary {
    color: #fff;
    border-color: #1492e6;
    background: #1492e6;
  }

  .ant-btn > span {
    font-weight: 500;
    font-size: 1.7rem;
    line-height: 2.4rem;
  }

  .ant-btn-primary[disabled],
  .stQWr .ant-btn-primary[disabled]:hover,
  .stQWr .ant-btn-primary[disabled]:focus,
  .stQWr .ant-btn-primary[disabled]:active {
    background: rgba(20, 146, 230, 0.5);
    border: rgba(20, 146, 230, 0.5);
    color: ${WHITE_COLOR};
  }

  .ant-form-item-explain,
  .ant-form-item-extra {
    transition: none;
  }

  & > .custom-helper > .ant-form-item-explain.ant-form-item-explain-error {
    margin-left: -48% !important;
  }
`;

export const FormTitle = styled.div`
  font-size: 4.2rem;
  line-height: 5.7rem;
  font-weight: 500;
  padding-bottom: 4rem;
  color: ${PRIMARY_COLOR};
  text-align: center;

  @media only screen and (max-width: 80em) {
    font-size: 4rem;
    line-height: 5.4rem;
  }
`;

export const SectionMainRight = styled.div`
  height: 100%;

  img {
    width: 100%;
    object-fit: fill;
    height: 100%;
  }
`;

export const ForgetPasswordContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 0.8rem;
  font-size: 2rem;
  line-height: 2.6rem;
`;

export const StyledLoginText = styled.div`
  font-size: 2rem;
  line-height: 2.6rem;
`;
