export const regexMessageMutiLang = (value) => {
  let regexMessage;

  if (value === "vi") {
    regexMessage = (
      <div>
        <div>Mật khẩu cần thoả mãn:</div>

        <div>- Có ít nhất 1 chữ viết hoa</div>
        <div>- Có ít nhất 1 chữ viết thường</div>
        <div>- Có ít nhất 1 ký tự số</div>
        <div>- Có ít nhất 1 ký tự đặc biệt !@#$%^&*()</div>
        <div>- Độ dài tối thiểu 6 ký tự</div>
      </div>
    );
  } else {
    regexMessage = (
      <div>
        <div>Password must:</div>

        <div>- At least one upper case</div>
        <div>- At least one lower case</div>
        <div>- At least one digit</div>
        <div>- At least one special character !@#$%^&*()</div>
        <div>- Minimum six in length</div>
      </div>
    );
  }

  return regexMessage;
};
