export const isValidEmailOrPhone = (value, EMAIL_OR_PHONE_VALIDATOR) => {
  const patternPhone =
    // eslint-disable-next-line no-useless-escape
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;
  const patternEmail =
    /^[a-zA-Z0-9]+[_a-zA-Z0-9.-]*[a-zA-Z0-9]+@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;

  if (
    !value ||
    value.trim().match(patternPhone) ||
    (value.trim().indexOf(" ") === -1 && patternEmail.test(value.trim()))
  ) {
    return Promise.resolve(true);
  }

  return Promise.reject(new Error(EMAIL_OR_PHONE_VALIDATOR));
};

export const isValidPhone = (value, error) => {
  const pattern =
    // eslint-disable-next-line no-useless-escape
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{1,6}$/im;

  if (!value || value.trim().match(pattern)) {
    return Promise.resolve(true);
  }

  return Promise.reject(new Error(error));
};
