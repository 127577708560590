import styled from 'styled-components'

export const ImageSliderContainer = styled.div`
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;

  .slick-slide img:nth-child(1) {
    margin: 0 auto;
    margin-top: 26vh;

    @media only screen and (min-width: 90.0625em) {
      margin-top: 30vh;
    }

    @media only screen and (max-width: 90em) {
      width: 30%;
    }

    @media only screen and (max-width: 80em) {
      margin-top: 34vh;
    }
  }

  .slick-slide img:nth-child(2) {
    margin-bottom: 0;
    margin: 0 auto;

    @media only screen and (max-width: 75em) {
      max-width: 95%;
    }

    @media only screen and (max-width: 90em) {
      width: 72%;
    }
  }
`
