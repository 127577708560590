/* eslint-disable no-undef */
import { useLocation } from "react-router-dom";
import { RecaptchaScreenContainer } from "./style";
import { useEffect } from "react";
import Loader from "../../components/GlobalComponents/Loader";
const { REACT_APP_RECAPTCHA } = process.env;

const RecaptchaScreen = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    console.log("params lang", params.get("lang"));
    console.log("params width", params.get("width"));
    const script = document.createElement("script");

    script.src = `https://www.google.com/recaptcha/api.js?hl=${
      params.get("lang") || "en"
    }&onload=onloadCallback&render=explicit`;
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    window.onloadCallback = function () {
      grecaptcha.render('captcha_ele', {
        'sitekey' : REACT_APP_RECAPTCHA,
        'callback' : captchaCallback,
        'size': 'invisible'
      });
      setTimeout(() => {
        grecaptcha.execute()
      }, 1000)
    }

    window.captchaCallback = function (response) {
      console.log('response', response);
      if (typeof Captcha !== "undefined") {
        Captcha.postMessage(response);
      }
    };
  }, []);

  return (
    <RecaptchaScreenContainer
      width={params.get("width")}
      height={params.get("height")}
    >
      <div className="wrap-recaptcha">
        {/* <div
          className="g-recaptcha"
          data-sitekey={REACT_APP_RECAPTCHA}
          data-callback="captchaCallback"
          data-size="invisible"
        ></div> */}
        <Loader />
        <div id="captcha_ele"></div>
      </div>
    </RecaptchaScreenContainer>
  );
};

export default RecaptchaScreen;
