import { useEffect, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Statistic } from 'antd';
import Cookie from 'js-cookie';

import { userActiveLogin } from "../../../features/user/loginSlice";
import { CloseCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
  ContentContainer,
  StyledText,
  StyledPhone,
  StyledTitle,
  StyledBtn
} from "./styles";
import { useHistory } from "react-router-dom";

const { Countdown } = Statistic;

const { REACT_APP_CUSTOMER_URL ="https://dev-xwealth.datxasia.com",
REACT_APP_SALE_URL = "https://dev-sales.datxasia.com",
REACT_APP_ADMIN_URL="https://dev-insight.datxasia.com",
} = process.env;

function getCookieDomain() {
  const parts = window.location.hostname.split('.');
  const subdomain = parts.shift();
  console.log('getCookieDomain', parts.join('.'));
  if (parts.join('.').length) {
    return parts.join('.');
  }
  return window.location.hostname;
}

const ActiveAccountContainer = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState();
  const [linkLogin, setLinkLogin] = useState();
  const [appName, setAppName] = useState();
  const { t } = useTranslation(["translation"]);
  const history = useHistory();
  const checkParam = () => {
    const paramText = history.location.search.split("?")[1] || "";
    const paramArray = paramText.split("&");
    const emailParam = paramArray.find(p => p.includes("email"));
    let email = ""
    if (emailParam) {
      email = decodeURIComponent(emailParam.split("=")[1])
      setEmail(email);
    }
    const sourceParam = paramArray.find(p => p.includes("source"));
    let source = "customer";
    if (sourceParam) {
      source = decodeURIComponent(sourceParam.split("=")[1]);
      switch (source) {
        case "sale-portal":
          setAppName('Sale Portal');
          setLinkLogin(REACT_APP_SALE_URL)
          break;
        case "insight":
          setAppName('Insight');
          setLinkLogin(REACT_APP_ADMIN_URL)
          break;
      
        default:
          setAppName('XWealth');
          setLinkLogin(REACT_APP_CUSTOMER_URL)
          break;
      }
    }
    const tokenParam = paramArray.find(p => p.includes("token"));
    if (tokenParam) {
      const tokenActive = (tokenParam.split("=")[1]);
      dispatch(userActiveLogin({ email, token: tokenActive, source }));
    }
  }
  useEffect(() => {
    checkParam();
  }, [])

  const { 
    errorMessageLogin,
    isSuccessLogin,
    dataLogin,
  } = useSelector((state) => state.userLogin);
  console.log('dataLogin', dataLogin);

  const onRedirect = () => {
    if (appName === 'XWealth') {
      Cookie.set('access_token', dataLogin.access_token, { domain: getCookieDomain() });
      Cookie.set('refresh_token', dataLogin.refresh_token, { domain: getCookieDomain() });
      setTimeout(() => {
        window.location.href = linkLogin;
      }, 500);
    } else {
      window.location.href = `${linkLogin}/callback?access_token=${dataLogin.access_token}&refresh_token=${dataLogin.refresh_token}&access_expired=${dataLogin.expired_time}&expired_time=${dataLogin.expired_time}`;
    }
  }
  const deadline = Date.now() + 1000 * 6;

  return (
    <ContentContainer>
      <StyledTitle>{t("ActiveAccount.title")} {email}</StyledTitle>
      {isSuccessLogin ? (
        <div>
          <StyledText style={{"font-size": '80px', color: 'green'}}><CheckCircleOutlined/></StyledText>
          <StyledText>{t("ActiveAccount.content1")}</StyledText>
          <StyledText style={{"font-size": '16px'}}>{t("ActiveAccount.textBtn3", { name: `${appName}` })}</StyledText>
          <Countdown value={deadline} format="s[s]" onFinish={onRedirect} valueStyle={{color: '#030d3e', padding: 15, background: 'white', width: 60, height: 60, borderRadius: 30, margin: '0 auto', marginBottom: 20, fontSize: 20 }} />
          <Button type="primary" size='large' onClick={onRedirect}>
            {t("ActiveAccount.btnNext")}
          </Button>
        </div>
      ): null}
      {errorMessageLogin ? (
        <div>
        <StyledText><CloseCircleOutlined style={{color: 'red', "font-size": '80px'}}/></StyledText>
        <StyledText><text style={{color:'red'}}>{t("ActiveAccount.contentError")}</text></StyledText>
        </div>
      ) : null}
    </ContentContainer>
  );
};

export default ActiveAccountContainer;
