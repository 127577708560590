import {notification} from 'antd'
import {SmileOutlined, FrownOutlined} from '@ant-design/icons'

export const successNotification = (message, description) => {
  notification.open({
    message: message,
    description: description,
    icon: <SmileOutlined style={{color: '#158ee9'}} />,
    duration: 6,
  })
}

export const failureNotification = (message, description) => {
  notification.open({
    message: message,
    description: description,
    icon: <FrownOutlined style={{color: '#ff4d4f'}} />,
    duration: 6,
  })
}
