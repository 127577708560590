import styled from 'styled-components'
import {WHITE_COLOR} from '../../../../data/constants'

export const StyledWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 2.4rem;
    margin-top: 4rem;
  }

  padding-bottom: 12rem;
`
export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 4.2rem;
  line-height: 5.7rem;
  color: ${WHITE_COLOR};
  padding-top: 23.6%;
  padding-bottom: 4.4rem;
  text-align: center;

  @media only screen and (max-width: 61.96875em) {
    padding-top: 35%;
  }

  @media only screen and (max-width: 37.5em) {
    padding-top: 60%;
  }
`

export const StyledMainContainer = styled.div`
  width: 38rem;
`

export const StyledTitleConfirmCode = styled.div`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.2rem;
  padding-bottom: 3.2rem;
`

export const StyledText = styled.div`
  font-size: 1.7rem;
  line-height: 2.4rem;
  padding-bottom: 0.4rem;
`

export const StyledPhone = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 3.2rem;
`

export const CodeInputContainer = styled.div`
  .styles_react-code-input__CRulA > input {
    border: none;
    border-right: none;
    margin-right: 2rem;
    font-weight: 500;
    font-size: 2.7rem;
    line-height: 3.7rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: ${WHITE_COLOR};
    background: transparent;
  }

  .styles_react-code-input__CRulA > input:focus {
    border-bottom: 2px solid #1492e6;
  }

  .styles_react-code-input__CRulA > input:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .styles_react-code-input__CRulA > input:last-child {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }

  .styles_react-code-input__CRulA > input {
    font-family: 'Noto Sans Display', sans-serif;
  }
`

export const StyledOTPTimer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  margin-top: 4rem;

  span {
    font-family: 'Noto Sans Display', sans-serif !important;
    font-size: 1.7rem;
  }

  div {
    font-size: 1.7rem !important;

    button {
      font-size: 1.7rem !important;

      span {
        font-size: 1.7rem !important;
      }
    }
  }
`
