/* eslint-disable no-restricted-globals */
import {GlobalOutlined} from '@ant-design/icons'
import {Select} from 'antd'
import 'flag-icons/css/flag-icons.min.css'
import i18next from 'i18next'
import {useDispatch} from 'react-redux'
import {LANGUAGE_VALUE_LIST} from '../../../data/constants'
import {handleChangeLanguage} from '../../../features/languageConfig/languageConfigSlice'
import {StyledSelect} from './styles'

const LanguageSelector = () => {
  const {Option} = Select
  const dispatch = useDispatch()

  if (location.pathname === '/recaptcha') return null;

  let defaultValue = (
    <GlobalOutlined style={{fontSize: '2rem', color: 'white'}} />
  )

  function handleChange(value) {
    i18next.changeLanguage(value)

    dispatch(handleChangeLanguage(value))
  }

  return (
    <StyledSelect>
      <Select
        placeholder={defaultValue}
        style={{width: 60}}
        onChange={handleChange}>
        {LANGUAGE_VALUE_LIST.map((el) => {
          return (
            <Option value={el.language} key={el.id}>
              <span class={`flag-icon flag-icon-${el.flagIcon}`}></span>
            </Option>
          )
        })}
      </Select>
    </StyledSelect>
  )
}

export default LanguageSelector
