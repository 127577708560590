import { InfoCircleOutlined } from "@ant-design/icons";
import { Col, Modal, Tabs } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// import LogoMobileIcon from "../../../assets/icons/LogoMobile";
import ImageSlider from "../../../components/AuthScreenComponents/ImageSlider";
import LoginFormContainer from "../../../components/AuthScreenComponents/LoginRegisterScreenComponents/LoginFormContainer/LoginWithPassword";
import RegisterFormContainer from "../../../components/AuthScreenComponents/LoginRegisterScreenComponents/RegisterFormContainer";
import Loader from "../../../components/GlobalComponents/Loader";
import { resetErrorMessageLogin } from "../../../features/user/loginSlice";
import {
  resetErrorMessageRegister,
  userRegisterResendVerify,
} from "../../../features/user/registerSlice";
import {
  StyledIconContainer,
  StyledIconDesktopContainer,
} from "../../../stylesUtils/styles";
import { AuthContainer, LoginRegisterScreenContainer } from "./styles";
import BackIcon from '../../../assets/icons/back-icon.svg'
import DatxLogo from '../../../assets/images/DATXLogo_White.png'
const { TabPane } = Tabs;

const LoginRegisterScreen = () => {
  const { t } = useTranslation(["translation"]);

  const history = useHistory();

  console.log("history", history.location.hash);

  function callback(key) {
    console.log(key);

    if (key === "1") {
      history.push("#loginForm");
    } else {
      history.push("#registerForm");
    }
  }

  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.setItem("isVerifiedCode", false);
  }, [history]);

  const { errorCode } = useSelector((state) => state.userLogin);
  const { isSuccessRegisterReVerify, loadingRegisterResendVerify } =
    useSelector((state) => state.userRegister);
  const { value } = useSelector((state) => state.languageConfig);

  useEffect(() => {
    if (isSuccessRegisterReVerify) {
      history.push(`/confirm-code`);
      dispatch(resetErrorMessageRegister());
    }
  }, [dispatch, history, isSuccessRegisterReVerify]);

  const infoNotiModal = (message, description) => {
    Modal.info({
      icon: (
        <InfoCircleOutlined
          style={{
            fontSize: "38px",
            color: "#008CCF",
            marginBottom: "1.2rem",
          }}
        />
      ),
      closable: true,
      width: 450,
      wrapClassName: "info-noti-modal",
      title: message,
      centered() {},
      content: description,
      onOk() {
        dispatch(
          userRegisterResendVerify({
            email: localStorage.getItem("email"),
            lang: value,
          })
        );
      },
    });
  };

  useEffect(() => {
    if (errorCode === 150) {
      if (value === "vi") {
        infoNotiModal(
          "",
          "Tài khoản chưa được xác thực, bạn cần xác thực tài khoản trước khi đăng nhập!"
        );
      } else {
        infoNotiModal(
          "",
          "Account not verified. Please click the button below to verify it!"
        );
      }
      dispatch(resetErrorMessageLogin());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorCode]);

  return (
    <>
      <AuthContainer>
    
        <Col
          xs={{ span: 0 }}
          md={{ span: 0 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <StyledIconDesktopContainer>
            <img src={DatxLogo} width={177} height={80} alt="Logo Datx" />
            {/* <LogoMobileIcon /> */}
          </StyledIconDesktopContainer>
          <ImageSlider />
        </Col>
        <Col
          xs={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
          className="col-left"
        >
          <img src={BackIcon} alt="Go back icon" onClick={() => history.goBack()} style={{
            position: 'sticky',
            top: '3%',
            left: '1%',
            cursor: 'pointer'
          }} />
          <StyledIconContainer>
          <img src={DatxLogo} width={177} height={80} alt="Logo Datx Mobile" />
            {/* <LogoMobileIcon /> */}
          </StyledIconContainer>

          {loadingRegisterResendVerify && <Loader />}
          <LoginRegisterScreenContainer>
            <Tabs
              defaultActiveKey={
                history.location.hash === "#loginForm" ||
                history.location.hash === ""
                  ? "1"
                  : "2"
              }
              onChange={callback}
            >
              <TabPane tab={t("loginForm.title")} key="1">
                <LoginFormContainer />
              </TabPane>
              <TabPane tab={t("registerForm.title")} key="2">
                <RegisterFormContainer />
              </TabPane>
            </Tabs>
          </LoginRegisterScreenContainer>
        </Col>
      </AuthContainer>
    </>
  );
};

export default LoginRegisterScreen;
