import styled from 'styled-components'
import {WHITE_COLOR} from '../../../data/constants'
import {Button} from 'antd'

export const ContentContainer = styled.div`
  text-align: center;
  color: ${WHITE_COLOR};
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-btn-primary[disabled],
  .ant-btn-primary[disabled]:hover,
  .ant-btn-primary[disabled]:focus,
  .ant-btn-primary[disabled]:active {
    color: rgba(0, 0, 0, 0.25);
    border-color: rgba(43, 157, 232, 0.6);
    background: rgba(43, 157, 232, 0.6);
    color: ${WHITE_COLOR};
  }
`

export const StyledTitle = styled.div`
  font-weight: 500;
  font-size: 3.2rem;
  line-height: 4.2rem;
  padding-bottom: 3.2rem;
`

export const StyledText = styled.div`
  font-size: 1.7rem;
  line-height: 2.4rem;
  padding-bottom: 0.4rem;
`

export const StyledPhone = styled.div`
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  padding-bottom: 3.2rem;
`

export const CodeInputContainer = styled.div`
  .styles_react-code-input__CRulA > input {
    border: none;
    border-right: none;
    margin-right: 2rem;
    font-weight: 500;
    font-size: 2.7rem;
    line-height: 3.7rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    color: ${WHITE_COLOR};
    background: transparent;
  }

  .styles_react-code-input__CRulA > input:focus {
    border-bottom: 2px solid #1492e6;
  }

  .styles_react-code-input__CRulA > input:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .styles_react-code-input__CRulA > input:last-child {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }
`

export const StyledBtn = styled(Button)`
  margin: 4rem 0 1.6rem 0;
  height: 4.4rem;
  width: 34.3rem;
  border-color: #2b9de8;
  background: #2b9de8;
`

export const StyledBtnDisabled = styled(Button)`
  margin: 4rem 0 1.6rem 0;
  height: 4.4rem;
  width: 34.3rem;
`

export const StyledTextBottom = styled.div`
  font-size: 1.7rem;
  line-height: 2.4rem;
`

export const StyledOTPTimer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.7rem;
  margin-top: 4rem;

  span {
    font-family: 'Noto Sans Display', sans-serif !important;
    font-size: 1.7rem;
  }

  div {
    font-size: 1.7rem !important;

    button {
      font-size: 1.7rem !important;

      span {
        font-size: 1.7rem !important;
      }
    }
  }
`
