import {Col} from 'antd'
import LogoMobileIcon from '../../../assets/icons/LogoMobile'
import ConfirmCodeContainer from '../../../components/AuthScreenComponents/ConfirmCodeScreenComponents/ConfirmCodeContainer'
import ImageSlider from '../../../components/AuthScreenComponents/ImageSlider'
import {
  StyledIconContainer,
  StyledIconDesktopContainer,
} from '../../../stylesUtils/styles'
import {ConfirmScreenContainer} from './styles'

const ConfirmCodeScreen = () => {
  return (
    <div style={{display: 'flex'}}>
      <Col
        xs={{span: 0}}
        md={{span: 0}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}>
        <StyledIconDesktopContainer>
          <LogoMobileIcon />
        </StyledIconDesktopContainer>
        <ImageSlider />
      </Col>

      <Col
        xs={{span: 24}}
        md={{span: 24}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}
        className='col-left'>
        <StyledIconContainer>
          <LogoMobileIcon />
        </StyledIconContainer>
        <ConfirmScreenContainer>
          <ConfirmCodeContainer />
        </ConfirmScreenContainer>
      </Col>
    </div>
  )
}

export default ConfirmCodeScreen
