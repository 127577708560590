import {Col} from 'antd'
import {useDispatch, useSelector} from 'react-redux'
import LogoMobileIcon from '../../../assets/icons/LogoMobile'
import RecoveryAccountContainer from '../../../components/AuthScreenComponents/ForgotPasswordScreenComponents/RecoverAccountContainer'
import ImageSlider from '../../../components/AuthScreenComponents/ImageSlider'
import {
  resetStateForgotPassword,
  userForgotPasswordSelector,
} from '../../../features/user/forgotPasswordSlice'
import {
  StyledIconContainer,
  StyledIconDesktopContainer,
} from '../../../stylesUtils/styles'
import {ForgotPasswordScreenContainer} from './styles'

const ForgotPasswordScreen = () => {
  const dispatch = useDispatch()

  const {isSuccessSendReq} = useSelector(userForgotPasswordSelector)

  if (isSuccessSendReq) {
    setTimeout(() => {
      dispatch(resetStateForgotPassword())
    }, 1000)
  }

  return (
    <ForgotPasswordScreenContainer>
      <Col
        xs={{span: 0}}
        md={{span: 0}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}>
        <StyledIconDesktopContainer>
          <LogoMobileIcon />
        </StyledIconDesktopContainer>
        <ImageSlider />
      </Col>

      <Col
        xs={{span: 24}}
        md={{span: 24}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}
        className='col-left'>
        <StyledIconContainer>
          <LogoMobileIcon />
        </StyledIconContainer>
        <RecoveryAccountContainer />
      </Col>
    </ForgotPasswordScreenContainer>
  )
}

export default ForgotPasswordScreen
