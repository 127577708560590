import { createSlice } from "@reduxjs/toolkit";

const { REACT_APP_DEFAULT_LANGUAGE } = process.env;

const initialState = {
  value: REACT_APP_DEFAULT_LANGUAGE,
};

export const languageConfigSlice = createSlice({
  name: "languageConfig",
  initialState,
  reducers: {
    handleChangeLanguage: (state, { payload }) => {
      state.value = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { handleChangeLanguage } = languageConfigSlice.actions;
export const languageConfig = (state) => state.languageConfig.value;

export const languageConfigReducer = languageConfigSlice.reducer;
