/* -------------------------------------------------------------------------- */
/*                               REGISTER SLICE                               */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  failureNotification,
  successNotification,
} from "../../components/GlobalComponents/UIHandler/Notification";
import { userLogin } from "./loginSlice";

const initialState = {
  fullName: "",
  email: "",
  phone: "",
  refCode: "",
  loading: false,
  isRedirect: false,
  isSuccess: false,
  isSuccessRegisterVerify: false,
  isSuccessRegisterReVerify: false,
  errorMessageRegister: "",
};

const { REACT_APP_AUTH_URL } = process.env;

export const userRegister = createAsyncThunk(
  "users/userRegister",

  async (
    {
      fullName,
      email,
      phone,
      password,
      confirmPassword,
      refCode,
      countryCode,
      lang,
      customerType,
      taxCode,
    },
    { rejectWithValue, getState, dispatch }
  ) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          fullname: fullName,
          email: email.trim(),
          phone: phone.trim(),
          language: lang,
          password,
          confirmPassword,
          ref_code: refCode,
          country_code: countryCode,
          customer_type: customerType,
          tax_code: taxCode,
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("isVerifiedCode", true);

        localStorage.setItem("email", data.data.email);

        dispatch(resetErrorMessageRegister());

        return {
          ...data,
          fullName: fullName,
          email: email.trim(),
          phone: phone.trim(),
          language: lang,
          password,
          confirmPassword,
          ref_code: refCode,
          country_code: countryCode,
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          switch (data.error_code) {
            case 179:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Email đã tồn tại, vui lòng sử dụng địa chỉ email khác để đăng ký!"
              );
              break;

            case 151:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Tài khoản hiện không còn tồn tại trong hệ thống! Vui lòng liên hệ quản trị viên để được trợ giúp!"
              );
              break;

            default:
              break;
          }

          if (data.error_code !== 179 && data.error_code !== 151) {
            failureNotification("Đã xảy ra lỗi!", data.message);
          }
        } else {
          switch (data.error_code) {
            case 179:
              failureNotification(
                "Something went wrong!",
                "An account for that email already exists. Please enter a different email address!"
              );
              break;

            case 151:
              failureNotification(
                "Something went wrong!",
                "Account no longer exists! Please contact an administrator for help!"
              );
              break;

            default:
              break;
          }

          if (data.error_code !== 179 && data.error_code !== 151) {
            failureNotification("Something went wrong!", data.message);
          }
        }
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

export const userRegisterVerify = createAsyncThunk(
  "users/userRegisterVerify",

  async ({ email, lang, OTP }, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          email: email.trim(),
          language: lang,
          code: OTP,
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("isVerifiedCode", false);

        dispatch(
          userLogin({
            email: getState().userInfo.email,
            password: getState().userInfo.password,
            lang: getState().languageConfig.value,
          })
        );

        return {
          ...data,
          email: email.trim(),
          code: OTP,
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          if (data.error_code === 130) {
            failureNotification(
              "Đã xảy ra lỗi! ",
              "Mã xác nhận không hợp lệ, vui lòng thử lại!"
            );
          } else {
            failureNotification("Đã xảy ra lỗi!", data.message);
          }
        } else {
          if (data.error_code === 130) {
            failureNotification(
              "Something went wrong!",
              "Incorrect verification code, please try again!"
            );
          } else {
            failureNotification("Đã xảy ra lỗi!", data.message);
          }

          failureNotification("Something went wrong!", data.message);
        }
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

export const userRegisterResendVerify = createAsyncThunk(
  "users/userRegisterResendVerify",

  async ({ email, lang, OTP }, { rejectWithValue, getState, dispatch }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/resend-verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          language: lang,
          email: email.trim(),
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        if (getState().languageConfig.value === "vi") {
          successNotification("Thành công!", "Gửi mã xác minh thành công!");
        } else {
          successNotification(
            "Success!",
            "Successfully sent verification code!"
          );
        }

        return {
          ...data,
          email: email.trim(),
          code: OTP,
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          failureNotification("Đã xảy ra lỗi! ", data.message);
        } else {
          failureNotification("Something went wrong!", data.message);
        }

        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

export const userRegisterSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetErrorMessageRegister: (state) => {
      state.errorMessageRegister = "";
      state.isRedirect = false;
      state.isSuccessRegisterVerify = false;
      state.isSuccessRegisterReVerify = false;
    },
  },
  extraReducers: {
    [userRegister.pending]: (state) => {
      state.loading = true;
      state.isRedirect = false;
    },

    [userRegister.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.fullName = payload?.data.fullName;
      state.email = payload?.data.email;
      state.phone = payload?.data.phone;
      state.refCode = payload?.data.refCode;
      state.isRedirect = true;
      state.isSuccess = true;
    },

    [userRegister.rejected]: (state, { payload }) => {
      state.loading = false;
      state.isRedirect = false;
      state.errorMessageRegister = payload;
      state.isFailed = true;
    },

    [userRegisterVerify.pending]: (state) => {
      state.loadingRegisterVerify = true;
      state.isSuccessRegisterVerify = false;
    },

    [userRegisterVerify.fulfilled]: (state, { payload }) => {
      state.loadingRegisterVerify = false;
      state.isSuccessRegisterVerify = true;
    },

    [userRegisterVerify.rejected]: (state, { payload }) => {
      state.loadingRegisterVerify = false;
      state.isSuccessRegisterVerify = false;
      state.isFailedRegisterVerify = true;
      state.errorMessageRegister = payload;
    },

    [userRegisterResendVerify.pending]: (state) => {
      state.loadingRegisterResendVerify = true;
      state.isSuccessRegisterReVerify = false;
    },

    [userRegisterResendVerify.fulfilled]: (state, { payload }) => {
      state.loadingRegisterResendVerify = false;
      state.isSuccessRegisterReVerify = true;
    },

    [userRegisterResendVerify.rejected]: (state, { payload }) => {
      state.loadingRegisterResendVerify = false;
      state.isSuccessRegisterReVerify = false;
      state.errorMessageRegister = payload;
    },
  },
});

export const { resetErrorMessageRegister } = userRegisterSlice.actions;
export const userRegisterSelector = (state) => state.userRegister;
export const userRegisterReducer = userRegisterSlice.reducer;
