/* -------------------------------------------------------------------------- */
/*                                 LOGIN SLICE                                */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { failureNotification } from "../../components/GlobalComponents/UIHandler/Notification";
import { getOS } from '../../data/getOS'

const initialState = {
  loading: false,
  isSuccessLogin: false,
  isSendOTPLoginSMS: false,
  isSuccessLoginSMS: false,
  isRedirect: false,
  errorMessageLogin: "",
  otpType: "",
  emailOrPhone: "",
  countryCode: "",
  dataLogin: {}
};

const {
  REACT_APP_AUTH_URL = "https://dev-api.datxasia.com/api/auth",
  REACT_APP_WEBHOOK_URL = "https://dev-api.datxasia.com/webhook",
} = process.env;

/* --------------------------- LOGIN WITH PASSWORD -------------------------- */

let pathName = window.location.href;

// eslint-disable-next-line no-unused-vars
const [url, serviceCode] = pathName.split("code=");

export const userLogin = createAsyncThunk(
  "users/userLogin",

  async ({ email, password, lang }, { rejectWithValue, getState }) => {
    try {
      const response = await fetch(`${REACT_APP_WEBHOOK_URL}/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          email: email.trim(),
          password,
          device: {
            name: "",
            platform: getOS(),
            device_token: "",
          },
          language: lang,

          service_code: getState().userInfo.serviceCode,
        }),
      });

      let data = await response.json();
      console.log('data login', JSON.stringify(data));

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("typeOfConfirmation", "");
        localStorage.clear();

        let editedCallbackUrl;

        if (data.data.callback) {
          editedCallbackUrl = `${data.data.callback}&language=${
            getState().languageConfig.value
          }`;
        }

        window.location.href = editedCallbackUrl;

        return {
          ...data,
          email: email.trim(),
          password: password,
        };
      } else if (data.error_code === 150) {
        localStorage.setItem("isVerifiedCode", "true");
        return rejectWithValue(data.error_code);
      } else {
        if (getState().languageConfig.value === "vi") {
          switch (data.error_code) {
            case 199:
              failureNotification("Đã xảy ra lỗi! ", "Mật khẩu không hợp lệ!");
              break;

            case 110:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Không tìm thấy thông tin tài khoản, vui lòng đăng ký để sử dụng dịch vụ!"
              );
              break;

            case 197:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Service code không hợp lệ!"
              );
              break;

            case 151:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Tài khoản hiện không còn tồn tại trong hệ thống! Vui lòng liên hệ quản trị viên để được trợ giúp!"
              );
              break;

            case 3000:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Hành động của bạn bị hạn chế!"
              );
              break;

            default:
              break;
          }

          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000
          ) {
            failureNotification("Đã xảy ra lỗi! ", data.message);
          }
        } else {
          switch (data.error_code) {
            case 199:
              failureNotification("Something went wrong!", "Invalid password!");
              break;

            case 110:
              failureNotification(
                "Something went wrong!",
                "User is not registered. Please register the user first!"
              );
              break;

            case 197:
              failureNotification(
                "Something went wrong!",
                "Invalid service code!"
              );
              break;

            case 151:
              failureNotification(
                "Something went wrong!",
                "Account no longer exists! Please contact an administrator for help!"
              );
              break;

            case 3000:
              failureNotification(
                "Something went wrong!",
                "Restricted user activity!"
              );
              break;

            default:
              break;
          }

          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000
          ) {
            failureNotification("Something went wrong!", data.message);
          }
        }

        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

/* ----------------------------- LOGIN SEND SMS ----------------------------- */

export const userLoginSendSMS = createAsyncThunk(
  "users/userLoginSendSMS",

  async ({ phone, countryCode }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/login-sendsms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          phone: phone,
          country_code: countryCode,
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        return {
          ...data,
          phone: phone,
          country_code: countryCode,
        };
      } else if (data.error_code === 150) {
        localStorage.setItem("isVerifiedCode", "true");
        return rejectWithValue(data.error_code);
      } else {
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

/* ---------------------------- LOGIN VERIFY SMS ---------------------------- */
export const userLoginVerifySMS = createAsyncThunk(
  "users/userLoginVerifySMS",

  async ({ emailOrPhone, countryCode, OTP }, { rejectWithValue, getState }) => {
    try {
      const response = await fetch(`${REACT_APP_WEBHOOK_URL}/login-verifysms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          phone: emailOrPhone.trim(),
          country_code: countryCode,
          code: OTP,
          device: {
            name: "",
            platform: getOS(),
            device_token: "",
          },
          service_code: serviceCode,
        }),
      });

      if (serviceCode === undefined) {
        if (getState().languageConfig.value === "vi") {
          failureNotification("Đã xảy ra lỗi! ", "Service code is required!");
        } else {
          failureNotification(
            "Something went wrong!",
            "Service code is required!"
          );
        }

        return;
      }

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        setTimeout(() => {
          window.location.href = data.data.callback;
        }, 1500);

        return {
          ...data,
          phone: emailOrPhone.trim(),
          country_code: countryCode,
          code: OTP,
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          failureNotification("Đã xảy ra lỗi! ", data.message);
        } else {
          failureNotification("Something went wrong!", data.message);
        }
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

/* ------------------------- LOGIN VERIFY RESEND SMS ------------------------ */

export const userLoginReSendSMS = createAsyncThunk(
  "users/userLoginReSendSMS",

  async ({ phone, countryCode }, { rejectWithValue }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/login-sendsms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          phone: localStorage.getItem("emailOrPhone"),
          country_code: localStorage.getItem("countryCode"),
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        return {
          ...data,
          phone: phone,
          country_code: countryCode,
        };
      } else {
        failureNotification("Somthing went wrong!", "Please try again!");
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);
export const userActiveLogin = createAsyncThunk(
  "users/userActiveLogin",

  async ({ email, token, source }, { rejectWithValue, getState }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/activate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email.trim(),
          token,
          source,
          device: {}
        }),
      });
      let data = await response.json();
      if (response.status === 200 && data.status === true) {
        localStorage.setItem("typeOfConfirmation", "");
        localStorage.clear();

        return {
          ...data,
          email: email.trim(),
          token
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          switch (data.error_code) {
            case 199:
              failureNotification("Đã xảy ra lỗi! ", "Mật khẩu không hợp lệ!");
              break;

            case 110:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Không tìm thấy thông tin tài khoản, vui lòng đăng ký để sử dụng dịch vụ!"
              );
              break;

            case 197:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Service code không hợp lệ!"
              );
              break;
            case 4307:
                failureNotification(
                  "Tài khoản đã được kích hoạt",
                );
                break;
            case 151:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Tài khoản hiện không còn tồn tại trong hệ thống! Vui lòng liên hệ quản trị viên để được trợ giúp!"
              );
              break;

            case 3000:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Hành động của bạn bị hạn chế!"
              );
              break;

            default:
              break;
          }

          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000 && 
            data.error_code !== 4307 
          ) {
            failureNotification("Đã xảy ra lỗi! ", data.message);
          }
        } else {
          switch (data.error_code) {
            case 199:
              failureNotification("Something went wrong!", "Invalid password!");
              break;

            case 110:
              failureNotification(
                "Something went wrong!",
                "User is not registered. Please register the user first!"
              );
              break;

            case 197:
              failureNotification(
                "Something went wrong!",
                "Invalid service code!"
              );
              break;

            case 151:
              failureNotification(
                "Something went wrong!",
                "Account no longer exists! Please contact an administrator for help!"
              );
              break;
            case 4307:
                failureNotification(
                  "Actived account!",
                );
                break;
            case 3000:
              failureNotification(
                "Something went wrong!",
                "Restricted user activity!"
              );
              break;

            default:
              break;
          }
          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000 &&
            data.error_code !== 4307
          ) {
            failureNotification("Something went wrong!", data.message);
          }
        }

        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);



export const userReActiveLogin = createAsyncThunk(
  "users/userReActiveLogin",

  async ({ email }, { rejectWithValue, getState }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/reactivate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          email: email.trim(),
        }),
      });

      let data = await response.json();
      console.log('data', JSON.stringify(data.data));

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("typeOfConfirmation", "");
        localStorage.clear();

        let editedCallbackUrl;

        if (data.data.callback) {
          editedCallbackUrl = `${data.data.callback}&language=${
            getState().languageConfig.value
          }`;
        }

        window.location.href = editedCallbackUrl;

        return {
          ...data,
          email: email.trim(),
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          switch (data.error_code) {
            case 199:
              failureNotification("Đã xảy ra lỗi! ", "Mật khẩu không hợp lệ!");
              break;

            case 110:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Không tìm thấy thông tin tài khoản, vui lòng đăng ký để sử dụng dịch vụ!"
              );
              break;

            case 197:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Service code không hợp lệ!"
              );
              break;

            case 151:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Tài khoản hiện không còn tồn tại trong hệ thống! Vui lòng liên hệ quản trị viên để được trợ giúp!"
              );
              break;

            case 3000:
              failureNotification(
                "Đã xảy ra lỗi! ",
                "Hành động của bạn bị hạn chế!"
              );
              break;

            default:
              break;
          }

          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000
          ) {
            failureNotification("Đã xảy ra lỗi! ", data.message);
          }
        } else {
          switch (data.error_code) {
            case 199:
              failureNotification("Something went wrong!", "Invalid password!");
              break;

            case 110:
              failureNotification(
                "Something went wrong!",
                "User is not registered. Please register the user first!"
              );
              break;

            case 197:
              failureNotification(
                "Something went wrong!",
                "Invalid service code!"
              );
              break;

            case 151:
              failureNotification(
                "Something went wrong!",
                "Account no longer exists! Please contact an administrator for help!"
              );
              break;

            case 3000:
              failureNotification(
                "Something went wrong!",
                "Restricted user activity!"
              );
              break;

            default:
              break;
          }

          if (
            data.error_code !== 199 &&
            data.error_code !== 110 &&
            data.error_code !== 197 &&
            data.error_code !== 151 &&
            data.error_code !== 3000
          ) {
            failureNotification("Something went wrong!", data.message);
          }
        }

        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);
export const userLoginSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetErrorMessageLogin: (state) => {
      state.errorMessageLogin = "";
      state.isSuccessLogin = false;
      state.isSendOTPLoginSMS = false;
      state.errorCode = null;
    },
  },

  extraReducers: {
    /* --------------------------- LOGIN WITH PASSWORD -------------------------- */
    [userLogin.pending]: (state) => {
      state.loading = true;
      state.isSuccessLogin = false;
    },

    [userLogin.fulfilled]: (state, { payload, meta }) => {
      state.loading = true;
      state.isSuccessLogin = true;
    },

    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMessageLogin = payload;
      state.errorCode = payload;
      state.isSuccessLogin = false;
      state.isFailedLogin = true;
    },

    /* ----------------------------- LOGIN SEND SMS ----------------------------- */

    [userLoginSendSMS.pending]: (state) => {
      state.loading = true;
      state.isSendOTPLoginSMS = false;
    },

    [userLoginSendSMS.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSendOTPLoginSMS = true;
      state.otpType = "SMS";

      state.phone = payload?.data.phone;
      state.countryCode = payload?.data.countryCode;
      state.email = null;
    },

    [userLoginSendSMS.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorCode = payload;
      state.isFailedSendOTPLoginSMS = true;
    },

    /* ---------------------------- LOGIN VERIFY SMS ---------------------------- */

    [userLoginVerifySMS.pending]: (state) => {
      state.loadingVerifySMS = true;
      state.isSuccessLoginSMS = false;
    },

    [userLoginVerifySMS.fulfilled]: (state, { payload }) => {
      state.loadingVerifySMS = false;
      state.isSuccess = true;
      state.isSuccessLoginSMS = true;
    },

    [userLoginVerifySMS.rejected]: (state, { payload }) => {
      state.loadingVerifySMS = false;
      state.errorMessageLogin = payload;
      state.isSuccess = false;
      state.isSuccessLoginSMS = false;
      state.isFailedLoginSMS = true;
    },

    /* ------------------------- LOGIN VERIFY RESEND SMS ------------------------ */

    [userLoginReSendSMS.pending]: (state) => {
      state.loadingLoginReSendSMS = true;
      state.isSendOTPLoginSMS = false;
    },

    [userLoginReSendSMS.fulfilled]: (state, { payload }) => {
      state.loadingLoginReSendSMS = false;
      state.isSendOTPLoginSMS = true;
    },

    [userLoginReSendSMS.rejected]: (state, { payload }) => {
      state.loadingLoginReSendSMS = false;
      state.errorMessageLogin = payload;
      state.isSendOTPLoginSMS = false;
    },
  /* ------------------------- ACTIVE VERIFY  ------------------------ */

    [userActiveLogin.pending]: (state) => {
      state.loading = true;
      state.isSuccessLogin = false;
    },

    [userActiveLogin.fulfilled]: (state, { payload, meta }) => {
      state.loading = true;
      state.isSuccessLogin = true;
      state.dataLogin = payload.data?.dataToken || {};
    },

    [userActiveLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMessageLogin = payload;
      state.errorCode = payload;
      state.isSuccessLogin = false;
      state.isFailedLogin = true;
    },

    [userReActiveLogin.pending]: (state) => {
      state.loading = true;
      state.isSuccessLogin = false;
    },

    [userReActiveLogin.fulfilled]: (state, { payload, meta }) => {
      state.loading = true;
      state.isSuccessLogin = true;
    },

    [userReActiveLogin.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMessageLogin = payload;
      state.errorCode = payload;
      state.isSuccessLogin = false;
      state.isFailedLogin = true;
    },
  },
});


// Action creators are generated for each case reducer function
export const { resetErrorMessageLogin } = userLoginSlice.actions;

export const userLoginSelector = (state) => state.userLogin;
export const userLoginReducer = userLoginSlice.reducer;

