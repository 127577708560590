import {Col} from 'antd'
import LogoMobileIcon from '../../../assets/icons/LogoMobile'
import ActiveAccountScreenComponents from '../../../components/AuthScreenComponents/ActiveAccountScreenComponents'
import ImageSlider from '../../../components/AuthScreenComponents/ImageSlider'
import {
  StyledIconContainer,
  StyledIconDesktopContainer,
} from '../../../stylesUtils/styles'


import {ActiveAccountScreenContainer} from './styles'

const ActiveAccountScreen = () => {
  return (
    <div style={{display: 'flex'}}>
      <Col
        xs={{span: 0}}
        md={{span: 0}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}>
        <StyledIconDesktopContainer>
          <LogoMobileIcon />
        </StyledIconDesktopContainer>
        <ImageSlider />
      </Col>

      <Col
        xs={{span: 24}}
        md={{span: 24}}
        lg={{span: 12}}
        xl={{span: 12}}
        xxl={{span: 12}}
        className='col-left'>
        <StyledIconContainer>
          <LogoMobileIcon />
        </StyledIconContainer>
        <ActiveAccountScreenContainer>
          <ActiveAccountScreenComponents />
        </ActiveAccountScreenContainer>
      </Col>
    </div>
  )
}

export default ActiveAccountScreen
