import { Button, Form, Input } from "antd";
import OtpTimer from "otp-timer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactCodeInput from "react-verification-code-input";
import Loader from "../../../../components/GlobalComponents/Loader";
import { regexMessageMutiLang } from "../../../../data/utils";
import { userForgotPassword } from "../../../../features/user/forgotPasswordSlice";
import {
  resetStateResetPassword,
  userResetPassword,
  userResetPasswordSelector,
} from "../../../../features/user/resetPasswordSlice";
import { handleSaveUserInfo } from "../../../../features/user/userInfoSlice";
import BackButton from "../../BackButton";
import { StyledOTPTimer } from "../../ConfirmCodeScreenComponents/ConfirmCodeContainer/styles";
import { FormContainer } from "../../LoginRegisterScreenComponents/RegisterFormContainer/styles";
import {
  CodeInputContainer,
  StyledPhone,
  StyledText,
  StyledTitle,
  StyledTitleConfirmCode,
  StyledWrapper,
} from "./styles";

const SetNewPasswordContainer = () => {
  const [form] = Form.useForm();
  // eslint-disable-next-line no-unused-vars
  const [isCompleted, setIsCompleted] = useState(false);

  const [state, setState] = useState({
    email: "",
    countryCode: "",
    OTP: "",
  });

  const [isClickedTime, setisClickedTime] = useState(0);

  const dispatch = useDispatch();

  let emailLocal = localStorage.getItem("email");

  const { isFailed, isSuccess } = useSelector(userResetPasswordSelector);

  const { loading, isSuccessResetPassword } = useSelector(
    (state) => state.userResetPassword
  );

  const { value } = useSelector((state) => state.languageConfig);

  let email = localStorage.getItem("email");

  let mergedObj;

  const onFinish = (values) => {
    setisClickedTime(0);

    mergedObj = { ...state, ...values, lang: value, email: email };

    dispatch(handleSaveUserInfo({ email: email, password: values.password }));
    dispatch(userResetPassword(mergedObj));
  };

  useEffect(() => {
    console.log("CLICK TIME", isClickedTime);
  }, []);

  const handleCompleted = (values) => {
    setIsCompleted(true);

    setState({
      ...state,
      email: emailLocal,
      OTP: values,
    });
  };

  const handleChange = () => {
    setIsCompleted(false);
  };

  const submit = () => {
    dispatch(
      userForgotPassword({
        email: emailLocal,
        lang: value,
      })
    );
  };

  useEffect(() => {
    if (isFailed) {
      setTimeout(() => {
        setisClickedTime(0);
      }, 2000);
    }

    if (isSuccess) {
      setisClickedTime(1);
    }
  }, [isClickedTime, isFailed, isSuccess]);

  const { t } = useTranslation(["translation"]);

  useEffect(() => {
    if (isSuccessResetPassword) {
      setIsCompleted(false);

      setTimeout(() => {
        dispatch(resetStateResetPassword());
      }, 1000);
    }
  }, [dispatch, isClickedTime, isSuccessResetPassword]);

  let serviceCode = localStorage.getItem("serviceCode");

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "white",
      }}
    >
      {loading && <Loader />}
      <BackButton linkTo="/forgot-password" />

      {isSuccessResetPassword &&
        (!serviceCode ||
          serviceCode === "undefined" ||
          serviceCode === "null") && <Redirect to="/" />}

      <StyledTitle>{t("newPassword.title")}</StyledTitle>
      <StyledTitleConfirmCode>
        {t("newPassword.content1")}
      </StyledTitleConfirmCode>
      <StyledText>{t("newPassword.content2")} </StyledText>
      <StyledPhone>{email}</StyledPhone>
      <CodeInputContainer>
        <ReactCodeInput
          onComplete={handleCompleted}
          fieldWidth="4rem"
          onChange={handleChange}
        />
      </CodeInputContainer>

      <StyledOTPTimer>
        <span>{t("newPassword.content3")}&nbsp;</span>

        <OtpTimer
          seconds={30}
          minutes={0}
          resend={submit}
          text={t("newPassword.content4")}
          ButtonText={t("newPassword.textBtn1")}
          textColor="#ffffff"
          background="transparent"
          buttonColor="#1492E6"
        />
      </StyledOTPTimer>

      <FormContainer>
        <StyledWrapper>
          <Form
            form={form}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            style={{ width: "38rem" }}
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("newPassword.passwordInput.helperValidator1"),
                },

                {
                  //eslint-disable-next-line
                  pattern:
                    /^(?=.*[A-Z])(?=.*[!@#$%^&*()])(?=.*[0-9])(?=.*[a-z]).{6,}$/g,
                  message: regexMessageMutiLang(value),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                autocapitalize="off"
                name="password"
                placeholder={t("newPassword.passwordInput.placeholder")}
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: t(
                    "newPassword.confirmPasswordInput.helperValidator1"
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        t("newPassword.confirmPasswordInput.helperValidator2")
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                autocapitalize="off"
                name="confirmPassword"
                placeholder={t("newPassword.confirmPasswordInput.placeholder")}
              />
            </Form.Item>

            {loading || isClickedTime === 1 ? (
              <Form.Item shouldUpdate>
                {() => (
                  <Button disabled type="primary" block>
                    {t("newPassword.textBtn2")}
                  </Button>
                )}
              </Form.Item>
            ) : (
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length ||
                      !isCompleted
                    }
                  >
                    {t("newPassword.textBtn2")}
                  </Button>
                )}
              </Form.Item>
            )}
          </Form>
        </StyledWrapper>
      </FormContainer>
    </div>
  );
};

export default SetNewPasswordContainer;
