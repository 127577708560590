import OtpTimer from "otp-timer";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import ReactCodeInput from "react-verification-code-input";
import { userLoginSelector } from "../../../../features/user/loginSlice";
import {
  userRegisterResendVerify,
  userRegisterSelector,
  userRegisterVerify,
} from "../../../../features/user/registerSlice";
import Loader from "../../../GlobalComponents/Loader";
import BackButton from "../../BackButton";
import {
  CodeInputContainer,
  ContentContainer,
  StyledBtn,
  StyledBtnDisabled,
  StyledOTPTimer,
  StyledPhone,
  StyledText,
  StyledTitle,
} from "./styles";

const ConfirmCodeContainer = ({ data }) => {
  const [isCompleted, setIsCompleted] = useState(false);

  const [state, setState] = useState({
    email: "",
    countryCode: "",
    OTP: "",
  });

  const [isClickedTime, setisClickedTime] = useState(0);

  let emailLocal = localStorage.getItem("email");

  const {
    isSuccessRegisterVerify,
    isFailedRegisterVerify,
    loadingRegisterVerify,
    loadingRegisterResendVerify,
  } = useSelector(userRegisterSelector);
  const {
    isSuccessLoginSMS,
    isFailedLoginSMS,
    loadingVerifySMS,
    loadingLoginReSendSMS,
  } = useSelector(userLoginSelector);

  const { value } = useSelector((state) => state.languageConfig);

  const handleCompleted = (values) => {
    setIsCompleted(true);

    setState({
      ...state,
      email: emailLocal,
      OTP: values,
    });
  };

  const handleChange = () => {
    setIsCompleted(false);
  };

  const [seconds, setSeconds] = useState(10);

  const handlerCountdown = () => {
    if (seconds > 0) {
      setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
    }
  };

  const submit = () => {
    dispatch(
      userRegisterResendVerify({
        lang: value,
        email: emailLocal,
      })
    );

    setState({
      ...state,
      OTP: "",
    });

    clear();
  };

  useEffect(() => {
    handlerCountdown();
  });

  const dispatch = useDispatch();

  let email = localStorage.getItem("email");
  let typeOfConfirmation = localStorage.getItem("typeOfConfirmation");

  const handleClick = () => {
    setisClickedTime(1);
    dispatch(userRegisterVerify({ ...state, lang: value }));
    clear();
  };

  useEffect(() => {
    if (isFailedLoginSMS || isFailedRegisterVerify) {
      setTimeout(() => {
        setisClickedTime(0);
      }, 6000);
    }

    if (isSuccessLoginSMS || isSuccessRegisterVerify) {
      setisClickedTime(1);
    }
  }, [
    isClickedTime,
    isFailedLoginSMS,
    isFailedRegisterVerify,
    isSuccessLoginSMS,
    isSuccessRegisterVerify,
  ]);

  const { t } = useTranslation(["translation"]);

  const codeRef = useRef(undefined);

  function clear() {
    if (codeRef.current.textInput[0]) codeRef.current.textInput[0].focus();
    codeRef.current.state.input[0] = "";
    codeRef.current.state.input[1] = "";
    codeRef.current.state.input[2] = "";
    codeRef.current.state.input[3] = "";
    codeRef.current.state.input[4] = "";
    codeRef.current.state.input[5] = "";
  }

  return (
    <>
      <ContentContainer>
        {(loadingRegisterVerify ||
          loadingVerifySMS ||
          loadingLoginReSendSMS ||
          loadingRegisterResendVerify) && <Loader />}

        {typeOfConfirmation === "loginSendSMS" ? (
          <BackButton linkTo="/login-sendsms" />
        ) : (
          <BackButton linkTo="/" />
        )}

        <StyledTitle>{t("confirmationCode.title")}</StyledTitle>
        <StyledText>{t("confirmationCode.content1")} </StyledText>

        <StyledPhone>{email}</StyledPhone>

        <CodeInputContainer>
          <ReactCodeInput
            onComplete={handleCompleted}
            fieldWidth="4rem"
            onChange={handleChange}
            ref={codeRef}
          />
        </CodeInputContainer>
        {!isCompleted || isClickedTime === 1 ? (
          <StyledBtnDisabled disabled type="primary" htmlType="submit" block>
            {t("confirmationCode.textBtn3")}
          </StyledBtnDisabled>
        ) : (
          <StyledBtn
            type="primary"
            htmlType="submit"
            block
            onClick={handleClick}
          >
            {t("confirmationCode.textBtn3")}
          </StyledBtn>
        )}

        <StyledOTPTimer>
          <span>{t("confirmationCode.content2")}&nbsp;</span>

          <OtpTimer
            seconds={30}
            minutes={0}
            resend={submit}
            text={t("confirmationCode.content3")}
            ButtonText={t("confirmationCode.textBtn2")}
            textColor="#ffffff"
            background="transparent"
            buttonColor="#1492E6"
          />
        </StyledOTPTimer>
      </ContentContainer>
    </>
  );
};

export default ConfirmCodeContainer;
