import {configureStore} from '@reduxjs/toolkit'
import {languageConfigReducer} from '../features/languageConfig/languageConfigSlice'
import {userForgotPasswordReducer} from '../features/user/forgotPasswordSlice'
import {userLoginReducer, userReActiveLogin, userActiveLogin} from '../features/user/loginSlice'
import {userRegisterReducer} from '../features/user/registerSlice'
import {userResetPasswordReducer} from '../features/user/resetPasswordSlice'
import {userInfoReducer} from '../features/user/userInfoSlice'

export const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
    userRegister: userRegisterReducer,
    userLogin: userLoginReducer,
    userForgotPassword: userForgotPasswordReducer,
    userResetPassword: userResetPasswordReducer,
    languageConfig: languageConfigReducer,
  },
})
