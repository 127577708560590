import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  userForgotPassword,
  userForgotPasswordSelector,
} from "../../../../features/user/forgotPasswordSlice";
import { handleSaveUserInfo } from "../../../../features/user/userInfoSlice";
import Loader from "../../../GlobalComponents/Loader";
import BackButton from "../../BackButton";
import { FormContainer } from "../../LoginRegisterScreenComponents/RegisterFormContainer/styles";
import {
  StyledMainContainer,
  StyledRecoveryAccountContainer,
  StyledTitle,
} from "./styles";

const RecoveryAccountContainer = () => {
  const [form] = Form.useForm();

  const [state, setState] = useState({
    email: "",
  });
  const history = useHistory()

  const [isClickedTime, setisClickedTime] = useState(0);

  const { t } = useTranslation(["translation"]);
  const { value } = useSelector((state) => state.languageConfig);

  function handleChange(evt) {
    let value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const dispatch = useDispatch();
  const { isSuccessSendReq, loading, isSuccess, isFailed } = useSelector(
    userForgotPasswordSelector
  );

  const onFinish = (values) => {
    setisClickedTime(1);
    dispatch(userForgotPassword({ ...values, lang: value }));
    dispatch(handleSaveUserInfo({ email: values.email, password: null }));
  };

  useEffect(() => {
    if (isFailed) {
      setTimeout(() => {
        setisClickedTime(0);
      }, 2000);
    }
  }, [isClickedTime, isFailed, isSuccess]);

  return (
    <StyledRecoveryAccountContainer>
      {loading && <Loader />}

      {/* <BackButton linkTo="/" /> */}
      <div
      onClick={() => history.goBack()}
        style={{
          position: 'fixed',
          top: '3rem',
          left: '3rem',
          cursor: 'pointer',
        }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='22'
          viewBox='0 0 28 22'
          fill='none'>
          <path
            d='M1.66675 11L0.959641 10.2929L0.252535 11L0.959641 11.7071L1.66675 11ZM26.6667 12C27.219 12 27.6667 11.5523 27.6667 11C27.6667 10.4477 27.219 10 26.6667 10V12ZM10.9596 0.292893L0.959641 10.2929L2.37385 11.7071L12.3739 1.70711L10.9596 0.292893ZM0.959641 11.7071L10.9596 21.7071L12.3739 20.2929L2.37385 10.2929L0.959641 11.7071ZM1.66675 12H26.6667V10H1.66675V12Z'
            fill='#1492E6'
          />
        </svg>
      </div>
      <StyledTitle>{t("recoveryAccount.title")}</StyledTitle>

      {isSuccessSendReq ? <Redirect to="/reset-password" /> : null}

      <StyledMainContainer>
        <FormContainer>
          <Form
            form={form}
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("recoveryAccount.emailInput.helperValidator2"),
                },
              ]}
              hasFeedback
            >
              <Input
                autocapitalize="off"
                name="email"
                value={state.email}
                onChange={handleChange}
                placeholder={t("recoveryAccount.emailInput.placeholder")}
                style={{ borderRadius: "0 0.8rem 0.8rem 0 !important" }}
              />
            </Form.Item>

            {loading || isClickedTime === 1 ? (
              <Form.Item shouldUpdate>
                {() => (
                  <Button disabled type="primary" block>
                    {t("recoveryAccount.textBtn")}
                  </Button>
                )}
              </Form.Item>
            ) : (
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {t("recoveryAccount.textBtn")}
                  </Button>
                )}
              </Form.Item>
            )}
          </Form>
        </FormContainer>
      </StyledMainContainer>
    </StyledRecoveryAccountContainer>
  );
};

export default RecoveryAccountContainer;
