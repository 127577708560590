import {Link} from 'react-router-dom'

const BackButton = ({linkTo}) => {
  return (
    <Link to={linkTo}>
      <div
        style={{
          position: 'fixed',
          top: '3rem',
          left: '3rem',
          cursor: 'pointer',
        }}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='28'
          height='22'
          viewBox='0 0 28 22'
          fill='none'>
          <path
            d='M1.66675 11L0.959641 10.2929L0.252535 11L0.959641 11.7071L1.66675 11ZM26.6667 12C27.219 12 27.6667 11.5523 27.6667 11C27.6667 10.4477 27.219 10 26.6667 10V12ZM10.9596 0.292893L0.959641 10.2929L2.37385 11.7071L12.3739 1.70711L10.9596 0.292893ZM0.959641 11.7071L10.9596 21.7071L12.3739 20.2929L2.37385 10.2929L0.959641 11.7071ZM1.66675 12H26.6667V10H1.66675V12Z'
            fill='#1492E6'
          />
        </svg>
      </div>
    </Link>
  )
}

export default BackButton
