import styled from 'styled-components'
import ArrowLeft from '../assets/icons/ArrowLeft.svg'
import ArrowRight from '../assets/icons/ArrowRight.svg'
import {WHITE_COLOR} from '../data/constants'
import ImageBg from '../assets/images/AuthScreen_BackgroundMobile.png'
import ImageBgTablet from '../assets/images/AuthScreen_BackgroundTablet.png'

/* -------------------------------------------------------------------------- */
/*                                STYLE GLOBAL                                */
/* -------------------------------------------------------------------------- */

export const StyledGlobalStyles = styled.div`
  position: relative;
  height: 100vh;
  overflow-y: hidden;

  > div > div:nth-child(2) {
    height: 100vh;
    overflow-y: scroll;
  }

  .ant-form-item-explain-error {
    color: ${WHITE_COLOR};
  }

  .col-left {
    background: #030d3e;

    @media only screen and (max-width: 62rem) {
      background-image: url(${ImageBgTablet});
      background-size: 100vw;
      object-fit: fill;
    }

    @media only screen and (max-width: 30rem) {
      background-image: url(${ImageBg});
      background-size: 100vw;
      object-fit: fill;
    }
  }

  .ant-tabs > .ant-tabs-nav,
  .ant-tabs > div > .ant-tabs-nav {
    @media only screen and (max-width: 30rem) {
      margin: 0 auto;
    }
  }
`

/* -------------------------------------------------------------------------- */
/*                                GLOBAL MIXINS                               */
/* -------------------------------------------------------------------------- */
export const basePaddingBigDesktop = `
  padding: 0 calc((100vw - 118rem) / 2) 8rem calc((100vw - 118rem) / 2);
`

/* -------------------------------------------------------------------------- */
/*                               SCREEN SECTION                               */
/* -------------------------------------------------------------------------- */

export const ScreenSectionHead = styled.div`
  padding: 8rem 0;
  text-align: center;

  @media only screen and (max-width: 56.25em) {
    max-width: 80rem;
    margin: 0 auto;
  }

  @media only screen and (max-width: 37.5em) {
    max-width: 55rem;
    margin: 0 auto;
  }

  @media only screen and (max-width: 30em) {
    max-width: 50rem;
    margin: 0 auto;
  }
`

export const ScreenSectionTitle = styled.div`
  font-size: 4.2rem;
  line-height: 5.7rem;
  font-weight: 700;
  padding-bottom: 1.6rem;
  text-align: center;
`

export const ScreenSectionTitleEng = styled.div`
  font-size: 2.1rem;
  line-height: 2.9rem;
  color: #bbbcbd;
  font-weight: 700;
  padding-bottom: 1.6rem;
  margin-top: -0.8rem;
`

/* -------------------------------------------------------------------------- */
/*                             HOME SCREEN SLIDER                             */
/* -------------------------------------------------------------------------- */
export const StyledSliderHomeScreen = styled.div`
  .slick-dots {
    bottom: -8rem;
  }

  .slick-prev,
  .slick-next {
    top: 35%;
  }

  .slick-prev::before,
  .slick-next::before {
    content: '←';
    font-size: 5rem;
    line-height: 1;
    opacity: 1;
    color: transparent;
    background-repeat: no-repeat;
    color: transparent;
  }

  .slick-next::before,
  .slick-prev::before {
    color: transparent;
  }

  .slick-prev {
    left: -6.4rem;

    @media only screen and (max-width: 30em) {
      left: -3.4rem;
    }
  }

  .slick-next {
    right: -4.4rem;

    @media only screen and (max-width: 30em) {
      right: -1.4rem;
    }
  }

  .slick-prev::before {
    background-image: url(${ArrowLeft});

    @media only screen and (max-width: 37.5em) {
      background-size: 90%;
    }
  }

  .slick-next::before {
    background-image: url(${ArrowRight});

    @media only screen and (max-width: 37.5em) {
      background-size: 90%;
    }
  }
`

/* -------------------------------------------------------------------------- */
/*                                 SUB SCREEN                                 */
/* -------------------------------------------------------------------------- */
export const SubScreenLayout = styled.div`
  height: 6rem;
  background-color: #030d3e;
  padding-top: 6rem;
`

export const StyledIconDesktopContainer = styled.div`
  position: absolute;
  top: 5%;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (max-width: 56.25em) {
    display: none;
  }
`

export const StyledIconContainer = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);

  @media only screen and (min-width: 61.96875em) {
    display: none;
  }
`

/* -------------------------------------------------------------------------- */
/*                           BODY STYLES AUTH SCREEN                          */
/* -------------------------------------------------------------------------- */

// let bodyStyles =
//   window.location.pathname === '/' ||
//   window.location.pathname === '/' ||
//   window.location.pathname === '/forgot-password' ||
//   window.location.pathname === '/reset-password' ||
//   window.location.pathname === '/reset-password' ||
//   window.location.pathname === '/login-sendsms' ||
//   window.location.pathname === '/confirm-code'
//     ? '#030d3e'
//     : null

// document.querySelector('body').style.background = bodyStyles

// export const GlobalStyle = styled.div``
