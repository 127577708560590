import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import {
  resetErrorMessageLogin,
  userLogin,
  userLoginSelector,
} from "../../../../features/user/loginSlice";
import { userRegisterResendVerify } from "../../../../features/user/registerSlice";
import { resetStateResetPassword } from "../../../../features/user/resetPasswordSlice";
import { handleSaveUserInfo } from "../../../../features/user/userInfoSlice";
import Loader from "../../../GlobalComponents/Loader";
import { FormContainer } from "../RegisterFormContainer/styles";
import { StyledLoginRegisterFormContainer } from "./styles";

const LoginRegisterFormContainer = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation(["translation"]);
  const { value } = useSelector((state) => state.languageConfig);

  const [state, setState] = useState({
    emailOrPhone: "",
    password: "",
    countryCode: "",
  });

  const [isClickedTime, setisClickedTime] = useState(0);

  function handleChange(evt) {
    let value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });
  }

  const { errorMessageLogin, loading, isFailedLogin, isSuccessLogin } =
    useSelector(userLoginSelector);

  const dispatch = useDispatch();

  const onFinish = (values) => {
    setisClickedTime(1);

    localStorage.setItem("email", values.email);

    dispatch(
      handleSaveUserInfo({ email: values.email, password: values.password })
    );

    dispatch(userLogin({ ...values, lang: value }));
  };

  if (errorMessageLogin === "unverified_user" && !loading) {
    dispatch(
      userRegisterResendVerify({
        email: localStorage.getItem("email"),
        lang: value,
      })
    );

    setTimeout(() => {
      dispatch(resetErrorMessageLogin());
    }, 1000);
  }

  useEffect(() => {
    if (isFailedLogin) {
      setTimeout(() => {
        setisClickedTime(0);
      }, 2000);
    }

    if (isSuccessLogin) {
      setisClickedTime(1);
    }
  }, [isClickedTime, isFailedLogin, isSuccessLogin]);

  const { isSuccessResetPassword } = useSelector(
    (state) => state.userResetPassword
  );

  useEffect(() => {
    if (isSuccessResetPassword) {
      dispatch(resetStateResetPassword());
    }
  }, [dispatch, isSuccessResetPassword]);

  return (
    <>
      {loading && <Loader />}

      {errorMessageLogin === "unverified_user" && !loading ? (
        <Redirect to="/confirm-code" />
      ) : null}

      {/* {isSuccessLogin ? <Redirect to='/dashboard' /> : null} */}

      <StyledLoginRegisterFormContainer>
        <FormContainer>
          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t("loginForm.emailInput.helperValidator2"),
                },
              ]}
              hasFeedback
            >
              <Input
                autocapitalize="off"
                name="email"
                value={state.emailOrPhone}
                onChange={handleChange}
                placeholder={t("loginForm.emailInput.placeholder")}
                style={{ borderRadius: "0 0.8rem 0.8rem 0 !important" }}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("loginForm.passwordInput.helperValidator1"),
                },
              ]}
            >
              <Input.Password
                autocapitalize="off"
                type="password"
                name="password"
                placeholder={t("loginForm.passwordInput.placeholder")}
                value={state.password}
                onChange={handleChange}
              />
            </Form.Item>

            <Form.Item>
              <div
                style={{ cursor: "pointer", color: "#1492E6" }}
                onClick={() => history.push(`/forgot-password`)}
              >
                {t("loginForm.forgotPassword")}
              </div>
            </Form.Item>

            {loading || isClickedTime === 1 ? (
              <Form.Item shouldUpdate>
                {() => (
                  <Button disabled type="primary" block>
                    {t("loginForm.textBtn")}
                  </Button>
                )}
              </Form.Item>
            ) : (
              <Form.Item shouldUpdate>
                {() => (
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                    block
                    disabled={
                      !form.isFieldsTouched(true) ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    {t("loginForm.textBtn")}
                  </Button>
                )}
              </Form.Item>
            )}
          </Form>
        </FormContainer>
      </StyledLoginRegisterFormContainer>
    </>
  );
};

export default LoginRegisterFormContainer;
