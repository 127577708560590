/* -------------------------------------------------------------------------- */
/*                            FORGOT PASSWORD SLICE                           */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  failureNotification,
  successNotification,
} from "../../components/GlobalComponents/UIHandler/Notification";

const { REACT_APP_AUTH_URL } = process.env;

const initialState = {
  isSuccessSendReq: false,
  errorMessageForgotPassword: "",
};

export const userForgotPassword = createAsyncThunk(
  "users/userForgotPassword",

  async ({ email, lang }, { getState, rejectWithValue }) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          email: email.trim(),
          language: lang,
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("email", data.data.email);
        localStorage.setItem("isVerifiedCode", true);

        if (getState().languageConfig.value === "vi") {
          successNotification("Thành công!", data.message);
        } else {
          successNotification("Success!", data.message);
        }

        return {
          ...data,
          email: email.trim(),
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          if (data.error_code === 110) {
            failureNotification(
              "Đã xảy ra lỗi! ",
              "Không tìm thấy thông tin tài khoản!"
            );
          } else {
            failureNotification("Đã xảy ra lỗi!", data.message);
          }
        } else {
          if (data.error_code === 110) {
            failureNotification("Something went wrong! ", "User not found!");
          } else {
            failureNotification("Something went wrong!", data.message);
          }
        }
        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);

export const userForgotPasswordSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetStateForgotPassword: (state) => {
      state.errorMessageForgotPassword = "";
      state.isSuccessSendReq = false;
    },
  },
  extraReducers: {
    [userForgotPassword.pending]: (state) => {
      state.loading = true;
    },

    [userForgotPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccessSendReq = true;
      state.isSuccess = true;
    },

    [userForgotPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMessageForgotPassword = payload;
      state.isSuccessSendReq = false;
      state.isFailed = true;
    },
  },
});

export const { resetStateForgotPassword } = userForgotPasswordSlice.actions;
export const userForgotPasswordSelector = (state) => state.userForgotPassword;
export const userForgotPasswordReducer = userForgotPasswordSlice.reducer;
