/* -------------------------------------------------------------------------- */
/*                            RESET PASSWORD SLICE                            */
/* -------------------------------------------------------------------------- */

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  failureNotification,
  successNotification,
} from "../../components/GlobalComponents/UIHandler/Notification";
import { userLogin } from "./loginSlice";

const { REACT_APP_AUTH_URL } = process.env;

const initialState = {
  isSuccessResetPassword: false,
  errorMessageResetPassword: "",
};

export const userResetPassword = createAsyncThunk(
  "users/userResetPassword",

  async (
    { OTP, email, lang, password },
    { rejectWithValue, dispatch, getState }
  ) => {
    try {
      const response = await fetch(`${REACT_APP_AUTH_URL}/reset-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },

        body: JSON.stringify({
          code: OTP,
          email: email.trim(),
          language: lang,
          password: password,
        }),
      });

      let data = await response.json();

      if (response.status === 200 && data.status === true) {
        localStorage.setItem("isVerifiedCode", false);

        let serviceCode = localStorage.getItem("serviceCode");

        if (
          !serviceCode ||
          serviceCode === "undefined" ||
          serviceCode === "null"
        ) {
          console.log("CHANGE PASSWORD ONLY");

          if (getState().languageConfig.value === "vi") {
            successNotification(
              "Thành công! ",
              "Vui lòng đăng nhập để tiếp tục sử dụng!"
            );
          } else {
            successNotification(
              "Success!",
              "Please login to continue using our service!"
            );
          }
        } else {
          dispatch(
            userLogin({
              email: getState().userInfo.email,
              password: getState().userInfo.password,
              lang: getState().languageConfig.value,
            })
          );
        }

        return {
          ...data,
          password: password,
        };
      } else {
        if (getState().languageConfig.value === "vi") {
          failureNotification("Đã xảy ra lỗi! ", data.message);
        } else {
          failureNotification("Something went wrong!", data.message);
        }

        return rejectWithValue(data.message);
      }
    } catch (error) {
      failureNotification("Something went wrong!", String(error));
      return rejectWithValue(String(error));
    }
  }
);
export const userResetPasswordSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetStateResetPassword: (state) => {
      state.errorMessageResetPassword = "";
      state.isSuccessResetPassword = false;
      state.isSuccess = false;
    },
  },
  extraReducers: {
    [userResetPassword.pending]: (state) => {
      state.loading = true;
    },

    [userResetPassword.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.isSuccessResetPassword = true;
      state.isSuccess = true;
    },

    [userResetPassword.rejected]: (state, { payload }) => {
      state.loading = false;
      state.errorMessageResetPassword = payload;
      state.isFailed = true;
    },
  },
});

export const { resetStateResetPassword } = userResetPasswordSlice.actions;
export const userResetPasswordSelector = (state) => state.userResetPassword;
export const userResetPasswordReducer = userResetPasswordSlice.reducer;
