import Slider from 'react-slick'
import Image from '../../../assets/images/AuthScreen_Background.png'
import Image1 from '../../../assets/images/AuthScreen_Image1.png'
import Image2 from '../../../assets/images/AuthScreen_Image2.png'
import {ImageSliderContainer} from './styles'

const ImageSlider = () => {
  const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <ImageSliderContainer style={{backgroundImage: `url(${Image})`}}>
      <Slider {...settings}>
        <div>
          <img src={Image2} alt='' />
          <img src={Image1} alt='' />
        </div>
        <div>
          <img src={Image2} alt='' />
          <img src={Image1} alt='' />
        </div>
        <div>
          <img src={Image2} alt='' />
          <img src={Image1} alt='' />
        </div>
      </Slider>
    </ImageSliderContainer>
  )
}

export default ImageSlider
