import styled from 'styled-components'

export const StyledTitle = styled.div``

export const RecaptchaScreenContainer = styled.div`
  background: white;
  height: 100vh;
  width: 100vw;
  position: relative;

  .wrap-recaptcha {
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};
  }
`
