import styled from 'styled-components'
import {WHITE_COLOR} from '../../../../data/constants'

export const StyledRecoveryAccountContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 4.2rem;
  line-height: 5.7rem;
  color: ${WHITE_COLOR};
  padding-top: 23.6%;
  padding-bottom: 4.4rem;
  text-align: center;

  @media only screen and (max-width: 61.96875em) {
    padding-top: 35%;
  }

  @media only screen and (max-width: 37.5em) {
    padding-top: 60%;
  }
`

export const StyledMainContainer = styled.div`
  width: 38rem;

  @media only screen and (max-width: 61.96875em) {
    width: 62rem;
  }

  @media only screen and (max-width: 37.5em) {
    width: 38rem;
  }
`
