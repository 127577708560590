import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  email: '',
  password: '',
  serviceCode: '',
}

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    handleSaveUserInfo: (state, {payload}) => {
      state.email = payload.email
      state.password = payload.password
    },

    handleSaveServiceCode: (state, {payload}) => {
      state.serviceCode = payload
    },
  },

  extraReducers: {},
})

export const {handleSaveUserInfo, handleSaveServiceCode} = userInfoSlice.actions
export const userInfoReducer = userInfoSlice.reducer
