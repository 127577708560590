import styled from "styled-components";

export const StyledLoginRegisterFormContainer = styled.section`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: flex-end;
  }

  .ant-form-item-control-input-content a.login-form-forgot {
    font-size: 1.3rem;
    line-height: 2rem;
    text-align: end;
  }

  .ant-btn.ant-btn-primary {
    height: 4.6rem;
  }

  .ant-tabs {
    overflow: visible !important;
  }
`;
