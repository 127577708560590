import { Button, Form, Input, Radio } from "antd";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector,  } from "react-redux";
import { Redirect, useHistory,useLocation } from "react-router-dom";
import { regexMessageMutiLang } from "../../../../data/utils";
import {
  userRegister,
  userRegisterSelector,
} from "../../../../features/user/registerSlice";
import { handleSaveUserInfo } from "../../../../features/user/userInfoSlice";
import CountryCodeSelect from "../../../GlobalComponents/CountryCodeSelect";
import Loader from "../../../GlobalComponents/Loader";
import { isValidPhone } from "../../../GlobalComponents/UIHandler/ValidatorHelper";
import BackButton from "../../BackButton";
import {
  FormContainer,
  RegisterFormSectionContainer,
  RegisterFormSectionMain,
  SectionMainLeft,
} from "./styles";



const RegisterFormContainer = () => {
  /* ------------------------------ FORM HANDLER ------------------------------ */

  const [form] = Form.useForm();
  const [formLayout, setFormLayout] = useState("horizontal");

  const onFormLayoutChange = ({ layout }) => {
    setFormLayout(layout);
  };

  const formItemLayout =
    formLayout === "horizontal"
      ? {
          labelCol: {
            span: 24,
          },
          wrapperCol: {
            span: 24,
          },
        }
      : null;
  const formRef = useRef();

  /* ------------------------------- STATE INPUT ------------------------------ */

  const params = useLocation().hash.split("?");
  const inviteCode = params.find(p => p.includes("invite"));
  let refCodeUser = ""
  if(inviteCode){
    refCodeUser = inviteCode.split("=")[1];
  }
 
  
  const [state, setState] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    refCode: refCodeUser,
    taxCode: "",
  });

  const [isClickedTime, setisClickedTime] = useState(0);

  const { t } = useTranslation(["translation"]); // MULTIPLE LANGUAGE HOOKS

  const [countryCode, setCountryCode] = useState(t("defaultCountryCode"));
  const [customerType, setCustomerType] = useState("person");

  const handleChangeCountryCode = (value) => {
    setCountryCode(value);
  };

  function handleChange(evt) {
    let value = evt.target.value;

    setState({
      ...state,
      [evt.target.name]: value,
    });

    console.log("STATE", state);
  }

  /* --------------- DISPATCH ACTION AND SELECT STATE FROM STORE -------------- */

  const { isRedirect, loading, isSuccess, isFailed } =
    useSelector(userRegisterSelector);

  const { value } = useSelector((state) => state.languageConfig);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    setisClickedTime(1);
    dispatch(
      userRegister({
        ...state,
        countryCode: `+${countryCode}`,
        lang: value,
        customerType,
      })
    );

    dispatch(
      handleSaveUserInfo({ email: state.email, password: state.password })
    );
  };

  useEffect(() => {
    if (isFailed) {
      setTimeout(() => {
        setisClickedTime(0);
      }, 2000);
    }

    if (isSuccess) {
      setisClickedTime(1);
    }
  }, [isClickedTime, isFailed, isSuccess]);

  const history = useHistory();

  /* ----------------------- HANDLE EMAIL OR PHONE INPUT ---------------------- */

  useEffect(() => {
    document.querySelector(
      "#RegisterForm .react-tel-input .form-control"
    ).disabled = true;
  });

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const onChangeRadio = (e) => {
    console.log("radio checked", e.target.value);

    form.resetFields();
    setCustomerType(e.target.value);
  };

  useEffect(() => {
    if (customerType === "person") {
      setState({
        ...state,
        taxCode: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerType]);

  return (
    <>
      {loading && <Loader />}

      <RegisterFormSectionContainer>
        <RegisterFormSectionMain id="RegisterForm">
          {history.location.pathname === "/login-sendsms" ? (
            <BackButton linkTo="/" />
          ) : null}

          {isRedirect ? <Redirect to={`/confirm-code`} /> : null}

          <SectionMainLeft>
            <FormContainer>
              <Form
                {...formItemLayout}
                layout="vertical"
                form={form}
                initialValues={{
                  layout: formLayout,
                }}
                onValuesChange={onFormLayoutChange}
                ref={formRef}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                  onChange={onChangeRadio}
                  style={{ height: "3.6rem" }}
                >
                  <Radio.Group value={customerType}>
                    <Radio value="person">
                      {t("registerForm.radioInput.option1")}
                    </Radio>
                    <Radio value="organization">
                      {t("registerForm.radioInput.option2")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item
                  name="fullName"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        "registerForm.nameInput.helperValidator1"
                      )}`,
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    autocapitalize="off"
                    name="fullName"
                    value={state.fullName}
                    onChange={handleChange}
                    placeholder={
                      customerType === "person"
                        ? t("registerForm.nameInput.placeholder")
                        : t("registerForm.organizationNameInput.placeholder")
                    }
                  />
                </Form.Item>

                {customerType === "organization" ? (
                  <Form.Item
                    name={customerType === "organization" ? "taxCode" : null}
                    rules={[
                      {
                        required: true,
                        message: `${t(
                          "registerForm.taxCodeInput.helperValidator1"
                        )}`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input
                      value={
                        customerType === "organization" ? state.taxCode : null
                      }
                      onChange={handleChange}
                      autocapitalize="off"
                      name="taxCode"
                      placeholder={t("registerForm.taxCodeInput.placeholder")}
                    />
                  </Form.Item>
                ) : null}

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        "registerForm.emailInput.helperValidator2"
                      )}`,
                    },

                    {
                      type: "email",
                      message: `${t(
                        "registerForm.emailInput.helperValidator1"
                      )}`,
                    },
                  ]}
                  hasFeedback
                >
                  <Input
                    value={state.email}
                    onChange={handleChange}
                    autocapitalize="off"
                    name="email"
                    placeholder={t("registerForm.emailInput.placeholder")}
                  />
                </Form.Item>

                <div style={{ display: "flex" }}>
                  <Form.Item style={{ width: "35%" }}>
                    <CountryCodeSelect
                      style={{ width: "35%" }}
                      name="countryCode"
                      countryCode={countryCode}
                      handleChangeCountryCode={handleChangeCountryCode}
                    />
                  </Form.Item>

                  <Form.Item
                    style={{ width: "68%" }}
                    rules={[
                      {
                        validator: (rule, value) =>
                          isValidPhone(
                            value.trim(),
                            t("registerForm.phoneInput.helperValidator1")
                          ),
                      },
                    ]}
                  >
                    <Input
                      autocapitalize="off"
                      name="phone"
                      placeholder={t("registerForm.phoneInput.placeholder")}
                      value={state.phone}
                      onChange={handleChange}
                    />
                  </Form.Item>
                </div>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        "registerForm.passwordInput.helperValidator1"
                      )}`,
                    },

                    {
                      //eslint-disable-next-line
                      pattern:
                        /^(?=.*[A-Z])(?=.*[!@#$%^&*()])(?=.*[0-9])(?=.*[a-z]).{6,}$/g,
                      message: regexMessageMutiLang(value),
                    },

                    // {
                    //   min: 8,
                    //   message: `${t(
                    //     "registerForm.passwordInput.helperValidator2"
                    //   )}`,
                    // },
                  ]}
                  hasFeedback
                >
                  <Input.Password
                    autocapitalize="off"
                    name="password"
                    value={state.password}
                    onChange={handleChange}
                    placeholder={t("registerForm.passwordInput.placeholder")}
                  />
                </Form.Item>

                <Form.Item
                  name="confirm"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: `${t(
                        "registerForm.confirmPasswordInput.helperValidator1"
                      )}`,
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }

                        return Promise.reject(
                          new Error(
                            `${t(
                              "registerForm.confirmPasswordInput.helperValidator2"
                            )}`
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autocapitalize="off"
                    name="confirmPassword"
                    value={state.confirmPassword}
                    onChange={handleChange}
                    placeholder={t(
                      "registerForm.confirmPasswordInput.placeholder"
                    )}
                  />
                </Form.Item>

                <Form.Item>
                  <Input
                    autocapitalize="off"
                    placeholder={t("registerForm.codeInput.placeholder")}
                    name="refCode"
                    value={state.refCode}
                    onChange={handleChange}
                  />
                </Form.Item>

                {loading || isClickedTime === 1 ? (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button disabled type="primary" block htmlType="submit">
                        {t("registerForm.textBtn")}
                      </Button>
                    )}
                  </Form.Item>
                ) : (
                  <Form.Item shouldUpdate>
                    {() => (
                      <Button
                        type="primary"
                        htmlType="submit"
                        block
                        disabled={
                          !form.isFieldsTouched(true) ||
                          !!form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          handleSubmit();
                        }}
                      >
                        {t("registerForm.textBtn")}
                      </Button>
                    )}
                  </Form.Item>
                )}
              </Form>
            </FormContainer>
          </SectionMainLeft>
        </RegisterFormSectionMain>
      </RegisterFormSectionContainer>
    </>
  );
};

export default RegisterFormContainer;
