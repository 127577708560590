const LogoMobileIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='177'
      height='80'
      viewBox='0 0 177 80'
      fill='none'>
      <g clip-path='url(#clip0_6071_12480)'>
        <path
          d='M2.90895 72.0383H0.0563965V71.1368H6.90857V72.0383H4.05602V79.8632H2.90911L2.90895 72.0383Z'
          fill='white'
        />
        <path
          d='M15.9648 71.1368H20.3066V72.0493H17.1058V74.9618H20.0603V75.8579H17.1058V78.9342H20.4386V79.8632H15.9648V71.1368Z'
          fill='white'
        />
        <path
          d='M29.3808 75.4538C29.3606 74.8328 29.4852 74.2157 29.7447 73.6514C30.0042 73.087 30.3916 72.591 30.876 72.2026C31.9541 71.3737 33.2897 70.952 34.6479 71.0115C34.8399 71.0115 35.0261 71.0169 35.2063 71.0276C35.3383 71.0351 35.4745 71.0442 35.6148 71.055C35.9429 71.095 36.2191 71.1406 36.4434 71.1918L36.4013 72.1642H36.3652C36.2331 72.1278 36.057 72.0895 35.8368 72.0494C35.4596 71.9874 35.0778 71.9581 34.6956 71.962C33.6245 71.9032 32.569 72.2396 31.7291 72.9074C31.3627 73.2262 31.073 73.6237 30.8816 74.0703C30.6903 74.5169 30.6022 75.001 30.624 75.4864C30.6091 75.9705 30.7016 76.4519 30.8947 76.896C31.0879 77.34 31.3769 77.7358 31.741 78.0548C32.5238 78.7234 33.5294 79.0725 34.5577 79.0327C34.9702 79.0369 35.3821 79.004 35.7886 78.9343C35.8819 78.9176 35.9742 78.8958 36.0651 78.8689L36.3412 78.787L36.3893 79.7374C36.1164 79.8203 35.8371 79.8806 35.5544 79.9177C35.1759 79.9651 34.7948 79.9889 34.4134 79.989C33.1337 80.0333 31.8814 79.612 30.8882 78.803C30.3971 78.4003 30.0056 77.8894 29.7441 77.3103C29.4826 76.7312 29.3583 76.0995 29.3809 75.4644L29.3808 75.4538Z'
          fill='white'
        />
        <path
          d='M53.1023 79.8632H51.9554V75.8688H47.1207V79.8632H45.9797V71.1368H47.1207V74.9617H51.9552V71.1368H53.1021L53.1023 79.8632Z'
          fill='white'
        />
        <path
          d='M70.44 79.9726H70.3018L64.8669 74.2298C64.6186 73.9641 64.3643 73.6781 64.104 73.3718H64.0799C64.0878 73.5721 64.0918 73.8416 64.092 74.1804V79.8633H63.035V71.0116H63.155L68.3197 76.5028L69.3586 77.6339H69.3827C69.3745 77.4152 69.3705 77.1347 69.3707 76.7924V71.1369H70.4398L70.44 79.9726Z'
          fill='white'
        />
        <path
          d='M89.6091 75.4644C89.6187 76.0654 89.4998 76.6615 89.2605 77.2128C89.0213 77.7641 88.667 78.2579 88.2216 78.6611C87.2639 79.5588 85.9899 80.0402 84.6784 80C83.4041 80.03 82.1688 79.5592 81.2373 78.6885C80.7915 78.2986 80.4352 77.817 80.1927 77.2766C79.9501 76.7362 79.8271 76.1497 79.8319 75.5572C79.8208 74.9472 79.9429 74.3422 80.1898 73.7844C80.4367 73.2266 80.8024 72.7295 81.2614 72.328C82.2461 71.4468 83.5322 70.9791 84.8525 71.022C86.1115 70.9833 87.3349 71.4429 88.2576 72.301C88.6941 72.6999 89.0405 73.1875 89.2736 73.7313C89.5066 74.275 89.621 74.8622 89.609 75.4538L89.6091 75.4644ZM88.402 75.4973C88.4121 75.0297 88.3238 74.5652 88.1427 74.134C87.9616 73.7029 87.6919 73.3147 87.3511 72.9947C86.6338 72.3365 85.6958 71.9719 84.7227 71.9729C83.7496 71.9739 82.8123 72.3405 82.0964 73.0002C81.7645 73.3255 81.501 73.714 81.3215 74.1429C81.1421 74.5717 81.0502 75.0322 81.0513 75.4971C81.0524 75.9621 81.1465 76.4221 81.3281 76.85C81.5096 77.278 81.7749 77.6653 82.1084 77.989C82.8208 78.6568 83.7595 79.0297 84.7356 79.0327C85.7116 79.0358 86.6526 78.6687 87.3692 78.0054C87.705 77.6837 87.9703 77.2956 88.1481 76.8657C88.3259 76.4358 88.4124 75.9735 88.4021 75.5084L88.402 75.4973Z'
          fill='white'
        />
        <path
          d='M99.0136 71.1368H100.154V78.8798C100.153 78.8871 100.154 78.8947 100.156 78.9017C100.159 78.9088 100.163 78.9153 100.168 78.9206C100.173 78.9258 100.179 78.9298 100.186 78.9322C100.194 78.9346 100.201 78.9353 100.208 78.9342H103.464V79.8632H99.0136V71.1368Z'
          fill='white'
        />
        <path
          d='M121.96 75.4643C121.97 76.0654 121.851 76.6615 121.612 77.2128C121.373 77.7641 121.018 78.2579 120.573 78.6611C119.615 79.5587 118.341 80.0401 117.03 80C115.756 80.03 114.52 79.5592 113.588 78.6885C113.143 78.2986 112.787 77.817 112.544 77.2765C112.301 76.7361 112.178 76.1496 112.183 75.5572C112.172 74.9472 112.294 74.3422 112.541 73.7844C112.788 73.2266 113.154 72.7295 113.613 72.328C114.597 71.4468 115.884 70.9791 117.204 71.022C118.463 70.9833 119.686 71.4429 120.609 72.301C121.046 72.6999 121.392 73.1875 121.625 73.7312C121.858 74.275 121.972 74.8622 121.96 75.4537L121.96 75.4643ZM120.753 75.4973C120.763 75.0297 120.675 74.5653 120.494 74.1341C120.313 73.7029 120.043 73.3147 119.702 72.9947C118.985 72.3365 118.047 71.9719 117.074 71.9729C116.101 71.9739 115.164 72.3405 114.448 73.0002C114.116 73.3254 113.852 73.714 113.673 74.1428C113.493 74.5716 113.401 75.0321 113.402 75.497C113.403 75.962 113.498 76.422 113.679 76.85C113.861 77.278 114.126 77.6653 114.459 77.989C115.172 78.6568 116.111 79.0297 117.087 79.0327C118.063 79.0358 119.004 78.6687 119.72 78.0054C120.056 77.6837 120.321 77.2955 120.499 76.8656C120.677 76.4357 120.763 75.9735 120.753 75.5084L120.753 75.4973Z'
          fill='white'
        />
        <path
          d='M130.824 75.4644C130.805 74.8367 130.932 74.213 131.197 73.6437C131.462 73.0743 131.857 72.5751 132.35 72.1862C133.414 71.3667 134.731 70.9471 136.073 71.0005C136.5 70.9993 136.927 71.0211 137.352 71.066C137.764 71.1354 138.077 71.1955 138.289 71.2463C138.285 71.3482 138.281 71.4505 138.277 71.5524C138.273 71.6288 138.269 71.7126 138.265 71.8036L138.253 72.2407H138.217C138.044 72.1819 137.868 72.1345 137.689 72.0988C137.46 72.0513 137.232 72.0149 137.004 71.9894C136.733 71.9574 136.46 71.941 136.187 71.9404C135.112 71.8883 134.055 72.2257 133.209 72.8909C132.83 73.2078 132.528 73.6077 132.328 74.0597C132.129 74.5116 132.035 75.0035 132.055 75.4973C132.037 75.9786 132.124 76.4581 132.31 76.9024C132.495 77.3468 132.775 77.7455 133.13 78.0708C133.901 78.7392 134.898 79.0873 135.917 79.0437C136.363 79.0496 136.808 79.0056 137.244 78.9124V75.8909H138.391V79.6391C137.573 79.8851 136.723 80.0031 135.869 79.989C134.565 80.0442 133.286 79.6162 132.278 78.7869C131.8 78.3814 131.421 77.8725 131.17 77.2988C130.918 76.7251 130.8 76.1015 130.824 75.4754L130.824 75.4644Z'
          fill='white'
        />
        <path
          d='M149.291 79.8632H148.15V71.1368H149.291V79.8632Z'
          fill='white'
        />
        <path
          d='M159.23 71.1368H163.571V72.0493H160.371V74.9618H163.325V75.8579H160.371V78.9342H163.704V79.8632H159.23V71.1368Z'
          fill='white'
        />
        <path
          d='M177 77.6938C177.001 77.9943 176.934 78.2912 176.805 78.5625C176.677 78.8339 176.488 79.0729 176.255 79.262C175.637 79.771 174.851 80.0303 174.051 79.989C173.563 79.9955 173.077 79.9273 172.61 79.7868L172.658 78.8085L173.102 78.9453C173.418 79.0129 173.74 79.0459 174.063 79.0437C174.491 79.0564 174.911 78.9278 175.258 78.6776C175.422 78.5646 175.556 78.4126 175.647 78.2352C175.738 78.0579 175.784 77.8607 175.78 77.6613C175.762 77.3829 175.679 77.1126 175.538 76.8718C175.398 76.6309 175.203 76.4261 174.97 76.2734L174.375 75.8144C174.163 75.6467 173.981 75.4973 173.829 75.3664C173.52 75.128 173.266 74.8251 173.086 74.4788C172.905 74.1324 172.802 73.751 172.784 73.3608C172.78 73.0609 172.844 72.764 172.969 72.4917C173.095 72.2195 173.28 71.9788 173.511 71.7872C174.099 71.2986 174.849 71.0491 175.612 71.0879C175.98 71.0778 176.348 71.1164 176.705 71.2028C176.681 71.5158 176.665 71.8326 176.657 72.1532H176.633C176.501 72.1078 176.364 72.0785 176.225 72.0658C176.055 72.0303 175.882 72.0139 175.708 72.0169C175.268 71.9976 174.833 72.1243 174.471 72.3774C174.315 72.4814 174.188 72.6229 174.1 72.7891C174.013 72.9553 173.969 73.1407 173.973 73.3283C173.98 73.5455 174.031 73.759 174.124 73.9555C174.217 74.1519 174.349 74.3271 174.513 74.4701C174.649 74.5978 174.844 74.76 175.096 74.9565L175.78 75.4973C176.124 75.7709 176.423 76.0953 176.669 76.4592C176.892 76.8282 177.006 77.2523 177 77.6833L177 77.6938Z'
          fill='white'
        />
        <path
          d='M74.8129 2.93693L78.5002 10.4512C79.8829 12.9512 81.1888 13.8353 84.4536 13.8353C84.4897 13.8353 96.0207 13.8609 96.0207 13.8609L96.0531 40.1221C96.0531 43.0436 100.048 50.3218 107.168 50.3218V13.8283H127.421L121.01 5.92296C120.415 5.00632 119.601 4.25303 118.641 3.73154C117.681 3.21006 116.606 2.9369 115.514 2.93689L74.8129 2.93693Z'
          fill='white'
        />
        <path
          d='M140.694 5.39867C140.038 4.62699 139.222 4.00719 138.302 3.58214C137.383 3.15709 136.383 2.93693 135.37 2.93689H124.803L159.586 47.7759C160.238 48.543 161.049 49.1591 161.963 49.5816C162.876 50.0041 163.87 50.223 164.876 50.223H175.367L140.694 5.39867Z'
          fill='white'
        />
        <path
          d='M176.974 2.86909C176.974 2.86909 173.501 2.7901 167.942 2.91103C164.408 2.98793 162.296 3.79517 160.332 6.12893C157.811 9.12335 153.5 13.9005 153.5 13.9005L162.911 13.8794C166.62 13.8711 171.13 12.0985 172.961 9.16754L176.974 2.86909Z'
          fill='white'
        />
        <path
          d='M174.583 0C174.583 0 171.442 0.395815 165.721 1.16349C164.276 1.30943 162.884 1.79215 161.658 2.57301C160.432 3.35386 159.406 4.41115 158.662 5.66021C156.627 8.97439 153.502 13.8847 153.502 13.8847L162.797 12.4852C166.459 11.9336 170.005 10.1289 171.624 6.81383L174.583 0Z'
          fill='#008CCF'
        />
        <path
          d='M150.818 18.5928C150.818 18.5928 143.006 29.0766 137.074 36.7394C125.09 52.2184 117.856 55.3065 107.909 55.3065C104.49 55.3065 96.7188 54.1148 91.444 44.9915L68.8979 2.80859L46.0062 44.8386C45.437 46.0969 44.6231 47.2292 43.6119 48.1694C42.6008 49.1095 41.4126 49.8386 40.1169 50.3142L52.5614 50.1604C54.7503 50.1604 56.2135 49.1823 57.2857 47.2723L68.8596 25.8605C70.2169 28.2453 82.0722 50.8858 82.0722 50.8858C86.7837 58.8303 97.4614 66.2431 109.157 66.2431C121.973 66.2431 133.261 59.9443 145.319 43.8895C153.922 32.434 157.955 27.2828 164.562 17.8263C162.614 18.8741 161.442 18.6314 159.592 18.6357L150.818 18.5928Z'
          fill='#008CCF'
        />
        <path
          d='M23.5882 2.93708L0 2.87109L0.0307179 50.1604H23.6394C38.1836 50.1604 47.1976 38.0464 47.1976 26.5166C47.1976 15.0585 38.2915 2.93708 23.5882 2.93708ZM23.9979 39.1484H10.9901V13.8838L23.9979 13.8798C31.0652 13.8798 36.1283 20.3379 36.1283 26.5796C36.1283 32.9978 31.1676 39.1484 23.9979 39.1484Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_6071_12480'>
          <rect width='177' height='80' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoMobileIcon
