import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { initReactI18next } from "react-i18next";
import { Provider } from "react-redux";
import App from "./App";
import Loader from "./components/GlobalComponents/Loader";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

const { REACT_APP_DEFAULT_LANGUAGE } = process.env;

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["vi", "en"],
    lng: `${REACT_APP_DEFAULT_LANGUAGE}`,
    fallbackLng: `${REACT_APP_DEFAULT_LANGUAGE}`,
    detection: {
      order: [
        "cookie",
        "htmlTag",
        "localStorage",
        "subdomain",
        "querystring",
        "sessionStorage",
        "path",
        "navigator",
      ],
      caches: ["cookie"],
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
    },
  });

const loadingMarkup = <Loader />;

ReactDOM.render(
  <Suspense fallback={loadingMarkup}>
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
