import styled from "styled-components";
import { WHITE_COLOR } from "../../../data/constants";

export const StyledPhoneInput = styled.div`
  .react-tel-input .form-control {
    position: relative;
    font-size: 1.7rem;
    margin-left: 0;
    background: transparent;
    border: ${WHITE_COLOR};
    border-radius: 0.8rem;
    line-height: 25px;
    height: 4.4rem;
    width: 90%;
    outline: none;
    color: white;
    border: 1px solid ${WHITE_COLOR};
  }

  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: rgba(20, 146, 230, 0.2);
  }

  .react-tel-input .selected-flag:hover,
  .react-tel-input .selected-flag:focus {
    background-color: rgba(20, 146, 230, 0.2);
  }

  .react-tel-input .selected-flag:hover,
  .ijaseH .react-tel-input .selected-flag:focus {
    background-color: rgba(20, 146, 230, 0.2);
  }

  .react-tel-input .country-list {
    background-color: #030d3e;
    border: 1px solid white;
    border-radius: 0.8rem;
    scrollbar-width: none;
  }

  .react-tel-input .country-list .country:hover {
    background-color: rgba(20, 146, 230, 0.2);
  }

  .react-tel-input .country-list .country.highlight {
    background-color: rgba(20, 146, 230, 0.2);
  }

  .country-name {
    color: ${WHITE_COLOR};
  }

  .react-tel-input .flag-dropdown {
    position: absolute;
    top: 0;
    bottom: 0;
    padding: 0;
    background-color: transparent;
    border: 1px solid ${WHITE_COLOR};
    border-radius: 3px 0 0 3px;
  }

  .react-tel-input .country-list .search {
    background-color: #030d3e;
    border-bottom: 1px solid ${WHITE_COLOR};
  }

  .react-tel-input .country-list .search-box {
    background-color: transparent;
    color: ${WHITE_COLOR};
    border: none;
  }

  .react-tel-input .country-list .no-entries-message {
    color: ${WHITE_COLOR};
  }
`;
