import cookies from 'js-cookie'
import Image from '../assets/images/AuthScreen_BackgroundMobile.png'

//Default Colors
export const WHITE_COLOR = '#FFFFFF'
export const TEXT_COLOR = '#1a1d21'
export const PRIMARY_COLOR = '#1492e6'
export const SECTION_BG_COLOR = 'rgba(19, 36, 57, 0.08)'

/* -------------------------------------------------------------------------- */
/*                              CONFIG LANGUAGES                              */
/* -------------------------------------------------------------------------- */

export let LANGUAGE_VALUE = cookies.get('i18next')
export const LANGUAGE_VALUE_LIST = [
  // id must be unique
  // language: HTML Language Code (https://www.w3schools.com/tags/ref_language_codes.asp)
  // flagIcon: For CSS Style (https://flagicons.lipis.dev/)

  {
    id: 1,
    language: 'vi',
    flagIcon: 'vn',
  },

  {
    id: 2,
    language: 'en',
    flagIcon: 'gb',
  },
]

/* -------------------------------------------------------------------------- */
/*                                   ******                                   */
/* -------------------------------------------------------------------------- */

let intViewportWidth = window.innerWidth
export let BACKGROUND_IMAGE

if (intViewportWidth < 480) {
  BACKGROUND_IMAGE = Image
} else {
}
