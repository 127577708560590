import styled from "styled-components";
import { WHITE_COLOR } from "../../../data/constants";

export const AuthContainer = styled.div`
  display: flex;
  margin: 0 auto;
  /* background: #030d3e; */
  position: relative;

  .ant-col:nth-child(2) {
    @media only screen and (max-width: 56.25em) {
      max-width: 100%;
    }
  }
`;

export const LoginRegisterScreenContainer = styled.div`
  max-width: 38rem;
  margin: 0 auto;
  padding-top: 23.6%;

  @media only screen and (max-width: 61.96875em) {
    padding-top: 35%;
    max-width: 62rem;
  }
  /* 
  @media only screen and (max-width: 80em) and (min-width: 56.3125em) {
    padding-top: 10vh;
  } */

  .ant-tabs {
    overflow: visible;
  }

  .ant-tabs-content-holder {
    margin-top: 4.4rem;
  }

  @media only screen and (min-width: 90.0625em) {
  }

  @media only screen and (max-width: 37.5em) {
    padding-top: 55%;
    padding-right: 4rem;
    padding-left: 4rem;
  }

  .ant-input,
  .ant-input-affix-wrapper {
    background-color: transparent;
    color: ${WHITE_COLOR};
    line-height: 2.1;
    font-size: 1.7rem;
  }

  .ant-form-item {
    margin-bottom: 2.4rem;
  }

  .ant-form-item-label > label {
    font-size: 1.7rem;
  }

  .ant-tabs-top > .ant-tabs-nav::before,
  .ant-tabs-bottom > .ant-tabs-nav::before,
  .ant-tabs-top > div > .ant-tabs-nav::before,
  .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .ant-tabs-tab-btn {
    font-size: 2.7rem;
    line-height: 3.7rem;
    color: rgba(255, 255, 255, 0.7);
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${WHITE_COLOR};
    text-shadow: 0 0 0.25px currentColor;
    font-size: 3.4rem;
    line-height: 5.1rem;
    font-weight: 700;
  }

  .ant-tabs-ink-bar {
    background: #1492e6;
    height: 4px !important;
  }

  .ant-tabs-tab-btn {
    outline: none;
    transition: none;
  }
`;
