import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { StyledPhoneInput } from "./styles";

const CountryCodeSelect = ({ countryCode, handleChangeCountryCode, name }) => {
  const { t } = useTranslation(["translation"]);

  // useEffect(() => {
  //   document.querySelector('.react-tel-input .form-control').disabled = true
  // })

  return (
    <StyledPhoneInput>
      <ReactPhoneInput
        inputExtraProps={{
          name: name,
          required: true,
          autoFocus: true,
          countryCodeEditable: false,
        }}
        placeholder="+XX"
        country={t("defaultCountryCode")}
        enableSearch={true}
        value={countryCode}
        onChange={handleChangeCountryCode}
        searchPlaceholder={t("searchPlaceholder")}
        searchNotFound="Not Found!"
        disableSearchIcon={true}
      />
    </StyledPhoneInput>
  );
};

export default CountryCodeSelect;
